import React from "react";
import { useSelector } from "react-redux";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogProps,
  DialogContentText,
} from "@material-ui/core";

import { selectInformation } from "../../Stores/informationSlice";

interface IProps {
  title: string;
  description?: string;
  body?: any;
  action: any;
  open: boolean;
  handleShow: (show: boolean) => void;
  yes?: boolean;
  no?: boolean;
  exit?: boolean;
  fullWidth?: boolean;
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl";
  dividers?: boolean;
}

const CustomModal: React.FC<IProps> = ({
  title,
  description,
  body,
  action,
  open,
  handleShow,
  fullWidth,
  maxWidth,
  dividers,
}) => {
  const scroll: DialogProps["scroll"] = "paper";
  const information = useSelector(selectInformation);

  return (
    <Dialog
      open={open}
      onClose={() => handleShow(false)}
      scroll={scroll}
      aria-labelledby="form-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <div
        style={{
          backgroundColor:
            information.darkTheme &&
            !window.location.pathname.startsWith("/panel")
              ? "#424242"
              : "#fff",
        }}
      >
        <DialogTitle
          style={{
            color:
              information.darkTheme &&
              !window.location.pathname.startsWith("/panel")
                ? "#fff"
                : "#000",
          }}
          id="scroll-dialog-title"
        >
          {title}
        </DialogTitle>
        <hr />
        <DialogContent
          style={{
            color:
              information.darkTheme &&
              !window.location.pathname.startsWith("/panel")
                ? "#fff"
                : "#000",
          }}
          dividers={dividers}
        >
          {description && (
            <DialogContentText
              style={{
                color:
                  information.darkTheme &&
                  !window.location.pathname.startsWith("/panel")
                    ? "#fff"
                    : "#000",
              }}
            >
              {description}
            </DialogContentText>
          )}
          {body}
        </DialogContent>
        <DialogActions>{action}</DialogActions>
      </div>
    </Dialog>
  );
};

export default CustomModal;
