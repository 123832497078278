import React, { Suspense, lazy, useState, useCallback, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import i18n from "./i18n";

import Skin3 from "./Layouts/Skin3";
import RTLTheme from "./Themes/RTLTheme";

import { IInformation } from "./Models/information";
import { IDeliveryCollection } from "./Models/deliveryCharge";
import Loading from "./components/Loading/Loading";
import LoggedInRoute from "./components/Common/LoggedInRoute/LoggedInRoute";
import AddToHomeScreen from "./components/Common/AddToHomeScreen/AddToHomeScreen";
import { selectUser } from "./Stores/userSlice";
import { setInformation } from "./Stores/informationSlice";
import { setDelivery } from "./Stores/deliverySlice";
import { setAllowDelivery } from "./Stores/alowDelivery";
import agent from "./Services/agent";
import { GetStoreId, SetStoreId } from "./Services/storeSession";
import { getLocalStorage, setLocalStorage } from "./Services/common";

const Admin = lazy(() => import("./Layouts/Admin/Admin"));
const KioskSkin = lazy(() => import("./Layouts/Kiosk"));
const Main = lazy(() => import("./Layouts/Main"));
const Main2 = lazy(() => import("./Layouts/Main2"));
const TopMenuSkin = lazy(() => import("./Layouts/TopMenuSkin"));
const OrderEntryLayout = lazy(() => import("./Layouts/OrderEntryLayout"));

const App = () => {
  // ========================useState=========================
  const [information, setLocalInformation] = useState<IInformation>();

  React.useLayoutEffect(() => {
    document.body.setAttribute(
      "dir",
      information !== undefined && information.langDisplay === "fa-IR"
        ? "rtl"
        : "ltr"
    );
  }, [information]);

  // =======================useSelector=======================
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  // =========================methods=========================
  const getInformation = useCallback(async () => {
    await agent.Information.get().then((res) => {
      dispatch(setInformation(res));
      setLocalInformation(res);
      if (res.minimumDeliveryTime <= 0) {
        dispatch(setAllowDelivery(false));
        dispatch(
          setDelivery({
            delivery: false,
            postCode: "",
            deliveryCharge: 0,
            minimumOrder: 0,
          })
        );
      } else {
        agent.DeliveryTimes.canDelivery()
          .then((res) => {
            if (res) {
              dispatch(setAllowDelivery(true));
            } else {
              dispatch(setAllowDelivery(false));
              dispatch(
                setDelivery({
                  delivery: false,
                  postCode: "",
                  deliveryCharge: 0,
                  minimumOrder: 0,
                })
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      setLocalStorage("DCDiscount", {
        deliveryOverallDiscount: res.deliveryOverallDiscount,
        collectionOverallDiscount: res.collectionOverallDiscount,
      });
      i18n.changeLanguage(res.lang === "Persian" ? "fa" : "en");
      let title = window.location.hostname.split(".")[0];
      if (title === "") title = "CaterSoft";
      if (title === "www") title = window.location.hostname.split(".")[1];
      window.document.title =
        res.websiteTitle !== "" && res.websiteTitle != null
          ? res.websiteTitle
          : title;
    });
  }, [dispatch]);

  const getStoreId = useCallback(async () => {
    var storeId = GetStoreId();
    if (storeId == null) {
      await fetch("/data/Store.json")
        .then((res) => res.json())
        .then(async (res) => {
          await SetStoreId(res.StoreId);
        })
        .finally(() => {
          window.location.reload();
        });
    } else getInformation();
  }, [getInformation]);

  const GetPostCode = () => {
    let postCode: IDeliveryCollection = getLocalStorage("postCode");
    if (postCode) {
      dispatch(
        setDelivery({
          delivery: postCode.delivery,
          postCode: postCode.postCode,
          deliveryCharge: postCode.deliveryCharge,
          minimumOrder: postCode.minimumOrder,
        })
      );
    } else {
      dispatch(
        setDelivery({
          delivery: false,
          postCode: "",
          deliveryCharge: 0,
          minimumOrder: 0,
        })
      );
    }
  };

  // =========================values==========================
  const isAdmin = user && user.roles.indexOf("Administrator") !== -1;

  // =======================useEffect=========================
  useEffect(() => {
    GetPostCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getStoreId();
  }, [getStoreId]);

  if (information === undefined) return <Loading />;
  // ==========================tsx============================
  if (information.langDisplay === "fa-IR") {
    return (
      <RTLTheme>
        <div className="App">
          <ToastContainer rtl />
          <Suspense fallback={<Loading />}>
            <Switch>
              <LoggedInRoute
                isLoggedIn={!!user.token}
                isInRole={isAdmin}
                path="/panel"
                component={Admin}
              />
              <LoggedInRoute
                isLoggedIn={!!user.token}
                isInRole={isAdmin}
                path="/order"
                component={OrderEntryLayout}
              />
              {information.storeType === "OriginKiosk" ? (
                <Route path="/" component={() => <KioskSkin />} />
              ) : information.theme === "Number2" ? (
                <Route path="/" component={() => <Main2 />} />
              ) : information.theme === "Number3" ? (
                <Route path="/" component={() => <Skin3 />} />
              ) : information.theme === "TopMenu" ? (
                <Route path="/" component={() => <TopMenuSkin />} />
              ) : (
                <Route path="/" component={() => <Main />} />
              )}
            </Switch>
            <AddToHomeScreen />
          </Suspense>
        </div>
      </RTLTheme>
    );
  } else {
    return (
      <div className="App">
        <ToastContainer />
        <Suspense fallback={<Loading />}>
          <Switch>
            <LoggedInRoute
              isLoggedIn={!!user.token}
              isInRole={isAdmin}
              path="/panel"
              component={Admin}
            />
            <LoggedInRoute
              isLoggedIn={!!user.token}
              isInRole={isAdmin}
              path="/order"
              component={OrderEntryLayout}
            />
            {information.storeType === "OriginKiosk" ? (
              <Route path="/" component={() => <KioskSkin />} />
            ) : information.theme === "Number2" ? (
              <Route path="/" component={() => <Main2 />} />
            ) : information.theme === "Number3" ? (
              <Route path="/" component={() => <Skin3 />} />
            ) : information.theme === "TopMenu" ? (
              <Route path="/" component={() => <TopMenuSkin />} />
            ) : (
              <Route path="/" component={() => <Main />} />
            )}
          </Switch>
          <AddToHomeScreen />
        </Suspense>
      </div>
    );
  }
};

export default App;
