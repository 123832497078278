import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import KeyboardArrowUpRoundedIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import {
  Grid,
  Fab,
  Hidden,
  ButtonBase,
  Container,
  Typography,
} from "@material-ui/core";

import ProductItemWithRedisCart from "./ProductItem2/ProductItemWithRedisCart";
// import ProductItem from "./ProductItem2/ProductItem2";
import ProductItemWithImage from "./ProductItem2/ProductItemWithImage";
import { IPagedCollection } from "../../../../Models/pagedCollection";
import { IMainProduct } from "../../../../Models/mainProduct";
import { IModifyCategory } from "../../../../Models/category";
import ScrollTop from "../../../Common/ScrollTop/ScrollTop";
import GoBack from "../../../Common/GoBackTrigger/GoBackTrigger";
import GoBackOfKiosk from "../../../Common/GoBackTrigger/GoBackOfKiosk";
import agent from "../../../../Services/agent";
import { selectInformation } from "../../../../Stores/informationSlice";
import { selectDelivery } from "../../../../Stores/deliverySlice";
import { history } from "../../../..";

import { ProductList2Styles } from "./ProductList2Styles";

interface IProps {
  match: any;
}

const ProductList2: React.FC<IProps> = ({ match }) => {
  const classes = ProductList2Styles();
  const { pathname } = useLocation();

  // =========================values==========================
  let categoryId = match.params.categoryId;

  // =======================useSelector=======================
  const information = useSelector(selectInformation);
  const delivery = useSelector(selectDelivery);

  // ========================useState=========================
  const [pagedProduct, setPagedProduct] = useState<
    IPagedCollection<IMainProduct>
  >({
    limit: 0,
    offset: 0,
    size: 0,
    value: [],
  });
  const [categoryForm, setCategoryForm] = useState<IModifyCategory>({
    id: "",
    name: "",
    description: "",
    isMenu: false,
    file: undefined,
    displayOrder: 0,
    backGroundColor: "",
    foreGroundColor: "",
    fontSize: 0,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  // =========================methods=========================
  function products(delivery: boolean) {
    if (delivery)
      return pagedProduct.value
        .filter((a) => a.delivery)
        .sort((a, b) => a.displayOrder - b.displayOrder);
    else
      return pagedProduct.value
        .filter((a) => a.collection)
        .sort((a, b) => a.displayOrder - b.displayOrder);
  }

  const getProducts = useCallback(async () => {
    await agent.MainProduct.list(categoryId, 100, 0, "")
      .then((res) => setPagedProduct(res))
      .catch(
        (error) =>
          error.status === 400 &&
          (history.push("/404"), localStorage.setItem("lastPath", "ordering"))
      );
  }, [categoryId]);

  const getCategory = useCallback(async () => {
    await agent.Categories.getById(categoryId).then((res) => {
      setCategoryForm(res);
    });
  }, [categoryId]);

  useEffect(() => {
    getProducts();
    getCategory();
    window.scrollTo(0, 0);
  }, [getProducts, getCategory, pathname]);

  // ==========================tsx============================
  return (
    <React.Fragment>
      <Container>
        <Typography
          className={classes.categoryTitle}
          variant="h4"
          component="h2"
        >
          {categoryForm.name}
        </Typography>
        {categoryForm.description && (
          <Grid item xs={12}>
            <div className={classes.description}>
              {categoryForm.description}
            </div>
          </Grid>
        )}
      </Container>

      <Grid item xs={12} className={classes.foods}>
        {
          //check kiosk field
          information.displayProductsWithImage ||
          information.storeType === "Kiosk" ||
          (information.storeType === "Takeaway" &&
            information.theme === "Number3")
            ? products(delivery.delivery).map((item) => (
                <ProductItemWithImage key={item.id} mainProduct={item} />
              ))
            : products(delivery.delivery).map((item) => (
                <ProductItemWithRedisCart key={item.id} mainProduct={item} />
              ))
        }
      </Grid>
      {(information.displayProductsWithImage ||
        information.storeType === "Kiosk") && (
        <GoBackOfKiosk>
          <ButtonBase>
            <ArrowBackRoundedIcon fontSize="small" />
          </ButtonBase>
        </GoBackOfKiosk>
      )}

      <ScrollTop>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpRoundedIcon />
        </Fab>
      </ScrollTop>
      <Hidden lgUp>
        {(information.displayProductsWithImage ||
          information.storeType !== "Kiosk") && (
          <GoBack>
            <ButtonBase>
              <ArrowBackRoundedIcon fontSize="small" />
            </ButtonBase>
          </GoBack>
        )}
      </Hidden>
    </React.Fragment>
  );
};

export default ProductList2;
