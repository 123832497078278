import React from "react";
import "./customPaging.scss";
// import { makeStyles, Theme, createStyles } from "@material-ui/core";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     bgColor: {
//       backgroundColor: theme.palette.primary.main,
//     },
//   })
// );

const CustomPaging = () => {
  // const classes = useStyles();
  return <button className="circleBtn"></button>;
};

export default CustomPaging;
