import React from "react";

import {
  makeStyles,
  createStyles,
  useScrollTrigger,
  Zoom,
  Theme,
} from "@material-ui/core";

import { history } from "../../..";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    goBack: {
      width: 30,
      height: 30,
      position: "fixed",
      bottom: 15,
      zIndex: 99,

      "@media(max-width:959px)": {
        bottom: 20,
      },

      "@media(max-width:599px)": {
        bottom: 20,
        left:20
      },

      "& button": {
        width: 30,
        height: 30,
        borderRadius: 50,
        backgroundColor: theme.palette.primary.main,
        color: "#fff"
        // color: theme.palette.text.primary
      },
    },
  })
);
interface IProps {
  children: React.ReactElement;
}

export default function GoBackOfKiosk(props: IProps) {
  const classes = useStyles();
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: -1,
  });
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    history.push("/ordering/departments/#top-anchor");
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.goBack}>
        {children}
      </div>
    </Zoom>
  );
}
