import React from "react";
import "./profilePassword.scss";
import { Form, Field } from "react-final-form";
import { IChangePassword } from "../../../Models/user";
import agent from "../../../Services/agent";
import { combineValidators, isRequired, matchesField } from "revalidate";
import { toast } from "react-toastify";
import { FORM_ERROR } from "final-form";
import ErrorMessage from "../../../utils/ErrorMessage";
import TextInput from "../../../utils/TextInput";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@material-ui/core";

const ProfilePassword = () => {
  const { t } = useTranslation();
  const validate = combineValidators({
    currentPassword: isRequired({ message: t("required") }),
    newPassword: isRequired({ message: t("required") }),
    confirmNewPassword: matchesField(
      "newPassword",
      "New Password"
    )({
      message: t("passwordsDoNotMatch"),
    }),
  });
  const changePasswordForm: IChangePassword = {
    confirmNewPassword: "",
    currentPassword: "",
    newPassword: "",
  };
  const onSubmit = async (values: IChangePassword) => {
    await agent.User.changePassword(values)
      .then((res) => {
        toast.success("your password changed");
      })
      .catch((error) => ({
        [FORM_ERROR]: error,
      }));
  };
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <h3 className="profileTitle">{t("changePassword")}</h3>
        <p className="profileDescribe">{t("hereYouCanChangeYourPassword")}</p>
      </Grid>
      <Form
        validate={validate}
        initialValues={changePasswordForm}
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          invalid,
          pristine,
          submitting,
          form,
          submitError,
          dirtySinceLastSubmit,
        }) => (
          <form
            onSubmit={(event) => {
              handleSubmit(event)?.then();
            }}
            style={{ width: "100%" }}
          >
            <Grid item xs={12} container justifyContent="center">
              <Grid item xs={12} md={6}>
                {submitError && !dirtySinceLastSubmit && (
                  <ErrorMessage text="Error(s)" error={submitError} />
                )}
                <Field
                  name="currentPassword"
                  autoFocus
                  label={t("currentPassword")}
                  component={TextInput}
                  type="password"
                  placeholder={t("currentPassword")}
                  required
                />
                <Field
                  name="newPassword"
                  autoFocus
                  label={t("newPassword")}
                  component={TextInput}
                  type="password"
                  placeholder={t("newPassword")}
                  required
                />
                <Field
                  name="confirmNewPassword"
                  autoFocus
                  label={t("confirmNewPassword")}
                  component={TextInput}
                  type="password"
                  placeholder={t("confirmNewPassword")}
                  required
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={
                    (invalid && !dirtySinceLastSubmit) || pristine || submitting
                  }
                >
                  {t("saveChanges")}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Grid>
  );
};

export default ProfilePassword;
