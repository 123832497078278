import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Paper } from "@material-ui/core";

import { CategoryMenu2Styles } from "./CategoryMenu2Styles";
import CategoryMenuItem2 from "./CategoryMenuItem2/CategoryMenuItem2";
import { ICategory } from "../../../Models/category";
import { selectInformation } from "../../../Stores/informationSlice";

interface IProps {
  toggleDrawer?: (open: boolean) => void;
  categories: ICategory[];
}
const CategoryMenu2: React.FC<IProps> = ({ toggleDrawer, categories }) => {
  const classes = CategoryMenu2Styles();
  const { t } = useTranslation();
  const information = useSelector(selectInformation);

  let nameOfMenu = <h4>{t("menu")}</h4>;
  if (information.displayProductsWithImage ||
    information.storeType === "Kiosk") {
    nameOfMenu = <></>;
  }

  return (
    <Paper className={`${classes.categoryContainer} 
    ${(information.displayProductsWithImage || information.storeType === "Kiosk") ?
        "categoryContainerOnKiosk" : ""}`}>
      {nameOfMenu}
      {categories.map((item) => (
        <CategoryMenuItem2
          toggleDrawer={toggleDrawer}
          key={item.id}
          category={item}
        />
      ))}
    </Paper>
  );
};

export default CategoryMenu2;
