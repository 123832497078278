import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const DeliveryAddressStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkout: {
      display: "flex",
      padding: "1rem",
    },
    addressLabel: {
      "& .Mui-checked~span": {
        fontWeight: 600,
      },
    },
    addressAction: {
      display: "flex",
      alignItems: "center",
      padding: "1rem",
      "@media only screen and (max-width: 600px)": {
        justifyContent: "space-between",
      },
      "& h5": {
        color: theme.palette.text.primary,
        "@media only screen and (min-width: 600px)": {
          marginRight: "1rem",
        },
      },
    },
    addressContainer: {
      padding: 5,

      "& hr": {
        margin: "0 0 10px 0",
      },
    },
    address: {
      "& p": {
        margin: 0,
        "& span": {
          color: theme.palette.text.primary,
          fontWeight: 600,
        },
      },
    },

    checkoutBtns: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",

      "@media only screen and (max-width: 600px)": {
        flexWrap: "wrap",
        justifyContent: "flex-end",
        order: 3,
        padding: "1rem 0",
      },
      "& button": {
        margin: 5,
      },
    },
  })
);
