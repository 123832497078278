import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const Ordering3Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.bg.main,
    },
    main: {
      backgroundColor: theme.palette.bg.main,
      height: "100%",
    },
    padding: {
      padding: "1rem",
    },
    sticky: {
      [theme.breakpoints.up("lg")]: {
        position: "sticky",
        top: "1rem",
      },
    },
    scrollMenu: {
      position: "sticky",
      padding: "1rem 1rem 2rem 0px",
      left: 0,
      overflowY: "scroll",
      top: 0,
      direction: "rtl",

      /* scrollbar width */
      "&::-webkit-scrollbar": {
        width: 20,
      },

      /* scrollbar Track */
      "&::-webkit-scrollbar-track": {
        background: theme.palette.bg.main,
      },

      /* scrollbar Handle */
      "&::-webkit-scrollbar-thumb": {
        border: `8px solid ${theme.palette.bg.main}`,
        borderRadius: 20,
        background: "#fff",
      },

      /* scrollbar Handle on hover */
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#eee",
      },
    },
    scrollMain: {
      // firefox
      scrollbarWidth: "thin",
      scrollbarColor: `${theme.palette.primary.main} #eee`,

      [theme.breakpoints.down("xs")]: {
        height: "auto !important",
      },

      [theme.breakpoints.up("sm")]: {
        position: "sticky",
        overflowY: "scroll",
        left: 0,
        top: 0,
        // marginTop: "1rem",

        /* scrollbar width */
        "&::-webkit-scrollbar": {
          width: 4,
        },

        /* scrollbar Track */
        "&::-webkit-scrollbar-track": {
          background: "none",
        },

        /* scrollbar Handle */
        "&::-webkit-scrollbar-thumb": {
          borderRadius: 20,
          background: theme.palette.primary.main,
        },

        /* scrollbar Handle on hover */
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#eee",
        },
        "&::-webkit-scrollbar-corner": {
          paddingTop: 10,
        },
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
      [theme.breakpoints.up("xl")]: {},
    },
  })
);
