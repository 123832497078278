import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Hidden, Link } from "@material-ui/core";
import PersonOutlineRoundedIcon from "@material-ui/icons/PersonOutlineRounded";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/styles";
import {
  usePopupState,
  bindHover,
  bindMenu,
} from "material-ui-popup-state/hooks";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { default as HoverMenu } from "material-ui-popup-state/HoverMenu";

import DrawerMenu from "./DrawerMenu/DrawerMenu";
import { MenuStyles } from "./MenuStyles";
import { selectUser } from "../../../../Stores/userSlice";
import { history } from "../../../..";
import { selectInformation } from "../../../../Stores/informationSlice";

const mobileMenuItems = [
  {
    name: "openingTimes",
    to: "/opening-time",
  },
  {
    name: "specialOffers",
    to: "/offers",
  },
  {
    name: "deliveries",
    to: "/delivery-information",
  },
  {
    name: "allergyAdvice",
    to: "/allergy-advice",
  },
  {
    name: "contactUs",
    to: "/contact",
  },
  {
    name: "parking",
    to: "/parking",
  },
  {
    name: "aboutUs",
    to: "/about",
  },
  {
    name: "Terms & Conditions",
    to: "/conditions",
  },
  {
    name: "privacy",
    to: "/privacy",
  },
];

const menuItems = [
  {
    name: "openingTimes",
    to: "/opening-time",
  },
  {
    name: "specialOffers",
    to: "/offers",
  },
  {
    name: "deliveries",
    to: "/delivery-information",
  },
  {
    name: "contactUs",
    to: "/contact",
  },
  {
    name: "parking",
    to: "/parking",
  },
];

const StyledMenuItem = withStyles(() => ({
  root: {
    fontWeight: "bold",
  },
}))(MenuItem);

interface IProps {
  toggleDrawer: (open: boolean) => void;
  address: string;
  tel: string;
}

const Menu: React.FC<IProps> = ({ toggleDrawer, address, tel }) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const isAdmin = user && user.roles.indexOf("Administrator") !== -1;
  const isUser = user && user.token;
  const isGuest = user.guest;
  const isTableOrder = user.tableOrder;
  const information = useSelector(selectInformation);

  const classes = MenuStyles();
  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoPopper",
  });

  const RemoveEmptyContentLink = (menu: any[]) => {
    let newMenu: any[] = [];
    menu.map((item) => {
      if (
        (item.name === "specialOffers" && !information.specialOffersText) ||
        (item.name === "deliveries" && !information.deliveryInformation) ||
        (item.name === "allergyAdvice" && !information.returns) ||
        (item.name === "contactUs" &&
          !information.contactUs &&
          !information.openingTime &&
          !(information.longitude && information.latitude)) ||
        (item.name === "parking" &&
          !information.parking &&
          !(information.parkingLatitude && information.parkingLongitude)) ||
        (item.name === "aboutUs" && !information.aboutUs) ||
        (item.name === "Terms & Conditions" && !information.termsCondition) ||
        (item.name === "privacy" && !information.privacyPolicy)
      ) {
        return false;
      } else {
        newMenu.push(item);
        return true;
      }
    });
    return newMenu;
  };

  const mobileMenuItemsWithoutEmptyContentLink =
    RemoveEmptyContentLink(mobileMenuItems);
  const menuItemsWithoutEmptyContentLink = RemoveEmptyContentLink(menuItems);

  return (
    <div className={classes.menu2}>
      <Hidden xsDown>
        <div className={classes.hoverMenu}>
          {window.location.pathname !== "/" && (
            <Link href={information.homePageAddress}>{t("home")}</Link>
          )}
        </div>

        <div className={classes.hoverMenu}>
          {window.location.pathname !== "/ordering/departments" && (
            <Link href="/ordering/departments">{t("orderNow")}</Link>
          )}
        </div>

        {menuItemsWithoutEmptyContentLink.map((item) => {
          return (
            <div key={item.name} className={classes.hoverMenu}>
              <Link href={item.to}>{t(item.name)}</Link>
            </div>
          );
        })}

        {isUser && !isGuest && !isTableOrder ? (
          <div className={classes.hoverMenu}>
            <a {...bindHover(popupState)}>
              <PersonOutlineRoundedIcon fontSize="small" />
              {user.firstName}
              <ArrowDropDownIcon />
            </a>
            <HoverMenu
              {...bindMenu(popupState)}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              {/* <MenuItem onClick={popupState.close}>Cake</MenuItem> */}
              {isAdmin && (
                <Link href="/panel" underline="none" className={classes.link}>
                  <StyledMenuItem onClick={popupState.close}>
                    {t("admin")}
                  </StyledMenuItem>
                </Link>
              )}
              <Link href="/profile" underline="none" className={classes.link}>
                <StyledMenuItem onClick={popupState.close}>
                  {t("profile")}
                </StyledMenuItem>
              </Link>
              <Link
                href="/"
                underline="none"
                className={classes.link}
                onClick={() => {
                  localStorage.removeItem("account");
                  window.location.replace("/");
                }}
              >
                <StyledMenuItem onClick={popupState.close}>
                  {t("signOut")}
                </StyledMenuItem>
              </Link>
            </HoverMenu>
          </div>
        ) : (
          <div className={classes.hoverMenu}>
            <Link
              href="/login"
              underline="none"
              onClick={() => history.push("/login")}
            >
              <PersonOutlineRoundedIcon fontSize="small" />
              {t("signIn")}
            </Link>
          </div>
        )}
      </Hidden>
      <Hidden smUp>
        <div>
          <DrawerMenu
            address={address}
            tel={tel}
            menu={mobileMenuItemsWithoutEmptyContentLink}
          />
        </div>
      </Hidden>
    </div>
  );
};

export default Menu;
