import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const CartStyles = makeStyles((theme: Theme) =>
  createStyles({
    cart2: {
      padding: "1rem",
      "& img": {
        width: 100,
        height: 100,
        borderRadius: "100%",
        margin: "1rem",
        objectFit: "cover",
        "@media only screen and (max-width: 600px)": {
          display: "none",
        },
      },

      "& .productTable": {
        "& td&th": {
          fontSize: "1rem",
          verticalAlign: "baseline",
        },
        "& th": {
          fontSize: "1.2rem",
          backgroundColor: "#ccc",
        },
        "& td": {
          borderBottom: "3px solid #ccc",
          fontWeight: 600,
          verticalAlign: "top",
          "& ul": {
            fontWeight: "normal",
          },
        },
      },

      "& .productTableXS": {
        "& td&th": {
          fontSize: "1rem",
          verticalAlign: "baseline",
        },
        "& th": {
          fontSize: "1.2rem",
          backgroundColor: "#ccc",
        },
        "& td": {
          fontWeight: 600,
          "& ul": {
            fontWeight: "normal",
          },
        },
        "& .extraPrice": {
          marginLeft: 5,
          fontWeight: 600,
        },
        "& .flexRow": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        "& .itemEnd": {
          borderBottom: "4px double #ccc",
        },
      },

      "& .extraPrice": {
        listStyle: "none",
        padding: 0,
      },

      "& .totalBold": {
        fontWeight: 600,
      },

      "& .nextBtn": {
        display: "flex",
        justifyContent: "flex-end",
        padding: "1rem",
        "& button": {
          margin: "0 5px",
        },
      },
    },
    extra: {
      maxWidth: 250,
      [theme.breakpoints.down('lg')]: {
        maxWidth: 220,
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 150,
      },
      "& li": {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
    },
  })
);
