import React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  useScrollTrigger,
  Zoom,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scrollTop: {
      position: "fixed",
      bottom: 20,
      zIndex: 99,

      [theme.breakpoints.down("xs")]: {
        right: 10,
      },
      [theme.breakpoints.down("md")]: {
        right: 20,
      },
      [theme.breakpoints.up("lg")]: {
        right: 350,
      },
      [theme.breakpoints.up("xl")]: {
        right: 500,
      },
    },
  })
);

interface IProps {
  children: React.ReactElement;
}
export default function ScrollTop(props: IProps) {
  const classes = useStyles();
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector("#top-anchor");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.scrollTop}
      >
        {children}
      </div>
    </Zoom>
  );
}
