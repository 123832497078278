import React, { useEffect, useState, useCallback } from "react";
import { Form, Field } from "react-final-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { Button, Grid } from "@material-ui/core";

import { combineValidators, isRequired } from "revalidate";
import { FORM_ERROR } from "final-form";

import agent from "../../Services/agent";
import { IModifyAddress } from "../../Models/address";
import Loading from "../Loading/Loading";
import ErrorMessage from "../../utils/ErrorMessage";
import TextInput from "../../utils/TextInput";

interface IProps {
  userId?: string;
  id?: string;
  postCode?: string;
  handleModal: (bool: boolean) => void;
  handleFunc?: () => void;
}

const ModifyAddress: React.FC<IProps> = ({
  userId,
  id,
  postCode,
  handleModal,
  handleFunc,
}) => {
  const { t } = useTranslation();
  const validate = combineValidators({
    zipPostalCode: isRequired({ message: t("required") }),
    phoneNumber: isRequired({ message: t("required") }),
    street: isRequired({ message: t("required") }),
    area: isRequired({ message: t("required") }),
  });
  const [isEdit, setIsEdit] = useState<boolean>(true);

  const [AddressForm, setAddressForm] = useState<IModifyAddress>({
    customerId: userId ? userId : undefined,
    fullName: "",
    address1: "",
    address2: "",
    city: "",
    company: "",
    countyInput: "",
    email: "",
    faxNumber: "",
    phoneNumber: "",
    zipPostalCode: postCode ? postCode : "",
    area: "",
    countryInput: "",
    notes: "",
    street: "",
    tel: "",
    town: "",
  });

  const getAddress = useCallback(
    async (addressId: string) => {
      await agent.Address.getById(addressId).then((res) => {
        if (userId) {
          setAddressForm({ ...res, customerId: userId });
        }
        setAddressForm(res);
        setIsEdit(false);
      });
    },
    [userId]
  );

  useEffect(() => {
    if (id) {
      getAddress(id);
    } else setIsEdit(false);
  }, [getAddress, id]);

  return (
    <Form
      validate={validate}
      initialValues={AddressForm}
      onSubmit={(values: IModifyAddress) =>
        id
          ? agent.Address.edit(values, id)
              .then((res) => {
                toast.success(t("editedSuccessfully"));
                handleModal(false);
              })
              .catch((error) => ({
                [FORM_ERROR]: error,
              }))
          : agent.Address.create(values)
              .then((res) => {
                toast.success(t("submittedSuccessfully"));
                handleModal(false);
                setIsEdit(false);
                if (handleFunc) handleFunc();
              })
              .catch((error) => ({
                [FORM_ERROR]: error,
              }))
      }
      render={({
        handleSubmit,
        invalid,
        pristine,
        submitting,
        submitError,
        dirtySinceLastSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          {isEdit && <Loading />}
          {submitError && !dirtySinceLastSubmit && (
            <ErrorMessage text="Error(s)" error={submitError} />
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {id ? <h5>{t("modifyAddress")}</h5> : <h5>{t("addAddress")}</h5>}
            </Grid>
            <Grid item xs={12}>
              <Field
                label={t("fullName")}
                autoFocus
                name="fullName"
                component={TextInput}
                placeholder={t("fullName")}
              />
              <Field
                label={t("mobile")}
                name="phoneNumber"
                component={TextInput}
                placeholder={t("mobile")}
              />
              <Field
                label={t("street")}
                name="street"
                component={TextInput}
                placeholder={t("street")}
              />
              <Field
                label={t("area")}
                name="area"
                component={TextInput}
                placeholder={t("area")}
              />
              <Field
                label={t("town")}
                name="town"
                component={TextInput}
                placeholder={t("town")}
              />
              <Field
                label={t("postCode")}
                name="zipPostalCode"
                component={TextInput}
                placeholder={t("postCode")}
                disable={postCode !== undefined && postCode !== ""}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={
                  (invalid && !dirtySinceLastSubmit) || pristine || submitting
                }
              >
                {t("submit")}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default ModifyAddress;
