import React, { useState, useCallback, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import {
  Grid,
  Paper,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  Hidden,
  FormControlLabel,
  Switch,
} from "@material-ui/core";

import Axios, { AxiosResponse } from "axios";

import { selectInformation, selectMoney } from "../Stores/informationSlice";
import AddCard from "../components/AddCard/AddCard";
import { IWorldPay } from "../Models/worldPay";
import visa from "../assets/img/visa.png";
import masterCard from "../assets/img/mastercard.png";
import maestro from "../assets/img/maestro.png";
import amex from "../assets/img/american-express.png";
import { selectDelivery } from "../Stores/deliverySlice";
import TimePicker from "../components/Common/TimePicker/TimePicker";
import CustomModal from "../components/Modal/CustomModal";
import PaymentSense from "../components/PaymentSense/PaymentSense";
import BackgroundImage from "../components/Common/BackgroundImage/BackgroundImage";
import { getLocalStorage, removeLocalStorage } from "../Services/common";
import { IOrderDisplay, ITableOrder } from "../Models/order";
import agent from "../Services/agent";
import { emptyCart } from "../Stores/redisCartSlice";
import { history } from "..";
import Loading from "../components/Loading/Loading";

import { PaymentStyles } from "./Styles/PaymentStyles";

interface IProps {
  match: any;
}

const PaymentPage: React.FC<IProps> = ({ match }) => {
  const classes = PaymentStyles();
  const { t } = useTranslation();
  const orderId = match.params.orderId;
  const dispatch = useDispatch();
  const information = useSelector(selectInformation);
  const delivery = useSelector(selectDelivery);
  const money = useSelector(selectMoney);
  const [worldPayModal, setWorldPayModal] = useState(false);
  const [cartList, setCartList] = useState<IOrderDisplay>();
  const [cardType, setCardType] = useState("VISA");
  const [loading, setLoading] = useState<boolean>(true);
  const [note, setNote] = useState<string>("");
  const [voucher, setVoucher] = useState<string>("");
  const [hasVoucher, setHasVoucher] = useState(false);
  const [startTime, setStartTime] = useState<Date>(
    new Date(
      new Date().getTime() +
        (delivery.delivery
          ? information.minimumDeliveryTime
          : information.minimumCollectionTime) *
          60000
    )
  );
  const [endTime, setEndTime] = useState<Date>(new Date());
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  const [disableTimePicker, setDisableTimePicker] = useState(true);
  const [asap, setAsap] = useState(true);
  const tableOrdering: ITableOrder = getLocalStorage("tableOrdering");

  const getHasVouchers = async () => {
    await agent.Voucher.hasActiveVoucher()
      .then((res) => {
        setHasVoucher(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getTimes = async (pickupInStore: boolean) => {
    await agent.Setting.deliveryStartEndTime(pickupInStore)
      .then((res) => {
        if (!asap) {
          setSelectedTime(new Date(res.startTime));
        }
        setStartTime(new Date(res.startTime));
        setEndTime(new Date(res.endTime));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleShow = () => {
    setWorldPayModal(false);
  };

  const handleAddCard = async (form: IWorldPay) => {
    var axios = Axios.create();
    delete axios.defaults.headers.Authorization;
    axios({
      method: "post",
      url: "/tokens",
      baseURL: "https://api.worldpay.com/v1",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
      },
      data: {
        reusable: true,
        paymentMethod: {
          name: form.name,
          expiryMonth: form.date.substring(0, 2),
          expiryYear: form.date.substring(3, 7),
          issueNumber: 1,
          startMonth: 2,
          startYear: 2020,
          cardNumber: form.cardNumber,
          type: "Card",
          cvc: form.cvc,
        },
        clientKey: information.worldPayClientKey,
      },
    }).then((res: any) => {
      agent.Order.edit(
        {
          id: orderId,
          payType: "WorldPay",
          note: note,
          wPayToken: res.data.token,
          deliveryTime: selectedTime,
        },
        orderId
      ).then((res) => {
        dispatch(emptyCart());
        history.push(`/ordering/order-status/${cartList?.id}/success`);
      });
    });
  };

  const getOrder = useCallback(async () => {
    await agent.Order.solo(orderId)
      .then((res) => {
        setCartList(res);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [orderId]);

  const handleASAP = () => {
    if (asap) {
      setSelectedTime(startTime);
      getTimes(!delivery.delivery);
    } else setSelectedTime(null);
    setAsap(!asap);
    setDisableTimePicker(!disableTimePicker);
  };

  const applyVoucherDiscountHandler = () => {
    agent.Order.applyVoucherDiscount({
      orderId: orderId,
      voucherCode: voucher,
    })
      .then((res) => {
        if (res) {
          window.location.reload();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    getTimes(!delivery.delivery);
    getOrder();
    getHasVouchers();
  }, [getOrder]);

  if (loading || !cartList) return <Loading />;
  return (
    <Fragment>
      <CustomModal
        title={t("worldPay")}
        body={<AddCard cardType={cardType} handleSubmit={handleAddCard} />}
        handleShow={handleShow}
        open={worldPayModal}
        action={null}
      />
      {information.themeDisplay !== "1" && <BackgroundImage />}
      <Grid
        container
        justifyContent="center"
        component={Paper}
        className={classes.Payment2}
      >
        <Grid item xs={12} sm={6} xl={6}>
          <h3>{t("confirmYourOrder")}</h3>
          <p>{t("checkoutYourOrder")}</p>
        </Grid>
        <Grid item xs={12} sm={6} xl={6} className="back">
          <Button
            onClick={() => history.goBack()}
            variant="contained"
            color="primary"
            size="large"
          >
            {t("back")}
          </Button>
        </Grid>

        <Grid item xs={12} xl={6}>
          <Hidden xsDown>
            <TableContainer className="productTable" component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("qty")}</TableCell>
                    <TableCell>{t("product")}</TableCell>
                    <TableCell>{t("price")}</TableCell>
                    <TableCell>{t("discount")}</TableCell>
                    <TableCell align="right">{t("total")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cartList.orderItems.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>
                        {item.productName}
                        <ul>
                          {item.orderItemProductAttributeValueMappings
                            .sort((a, b) => a.displayOrder - b.displayOrder)
                            .map((att) => (
                              <li key={att.id}>
                                {att.productAttributeValue.name}
                              </li>
                            ))}
                        </ul>
                        {item.notes}
                      </TableCell>
                      <TableCell>
                        {money(item.price)}
                        <ul>
                          {item.orderItemProductAttributeValueMappings.map(
                            (att) => (
                              <li key={att.id}>
                                <span>
                                  {att.productAttributeValuePriceAdjustment ===
                                  0 ? (
                                    <span>.</span>
                                  ) : (
                                    money(
                                      att.productAttributeValuePriceAdjustment
                                    )
                                  )}
                                </span>
                              </li>
                            )
                          )}
                        </ul>
                      </TableCell>
                      <TableCell>
                        {item.discountTitle ? item.discountTitle : "-"}
                      </TableCell>
                      <TableCell align="right" className="totalBold">
                        {money(
                          (item.orderItemProductAttributeValueMappings
                            .map(
                              (attr) =>
                                attr.productAttributeValuePriceAdjustment
                            )
                            .reduce((prev, curr) => prev + curr, 0) +
                            item.price) *
                            item.quantity
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow className={classes.total}>
                    <TableCell
                      colSpan={3}
                      rowSpan={delivery.delivery ? 5 : 4}
                    />
                    <TableCell>{t("subTotal")}</TableCell>
                    <TableCell align="right">
                      {money(cartList.orderTotal)}
                    </TableCell>
                  </TableRow>
                  {delivery.delivery && (
                    <TableRow className={classes.total}>
                      <TableCell>{t("deliveryFee")}</TableCell>
                      <TableCell align="right">
                        {money(cartList.deliveryCharge)}
                      </TableCell>
                    </TableRow>
                  )}
                  {cartList.serviceCharge > 0 && (
                    <TableRow className={classes.total}>
                      <TableCell>{t("serviceCharge")}</TableCell>
                      <TableCell align="right">
                        {money(cartList.serviceCharge)}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow className="discount">
                    <TableCell>{t("discount")}</TableCell>
                    <TableCell align="right">
                      <span
                        style={{
                          float:
                            information.lang === "Persian" ? "left" : "none",
                        }}
                      >
                        {"-"}&nbsp;
                      </span>
                      {money(cartList.discount)}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.total}>
                    <TableCell>{t("total")}</TableCell>
                    <TableCell align="right">
                      {money(
                        cartList.serviceCharge +
                          cartList.orderTotal -
                          cartList.discount +
                          (delivery.delivery ? cartList.deliveryCharge : 0)
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Hidden>
          <Hidden smUp>
            <TableContainer
              component={Paper}
              className={classes.productTableXS}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{t("qty")}</TableCell>
                    <TableCell>{t("product")}</TableCell>
                    <TableCell>{t("price")}</TableCell>
                    <TableCell align="right">{t("total")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cartList.orderItems.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell align="center">{item.quantity}</TableCell>
                      <TableCell>
                        {item.productName}
                        {item.notes}
                      </TableCell>
                      <TableCell>{money(item.price)}</TableCell>
                      <TableCell align="right" className="totalBold">
                        {money(
                          (item.orderItemProductAttributeValueMappings
                            .map(
                              (attr) =>
                                attr.productAttributeValuePriceAdjustment
                            )
                            .reduce((prev, curr) => prev + curr, 0) +
                            item.price) *
                            item.quantity
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  {cartList.orderItems.map((item) =>
                    item.orderItemProductAttributeValueMappings.length > 0 ? (
                      <TableRow key={item.id} className="extraRow">
                        <TableCell />
                        <TableCell>
                          <ul className="extra">
                            {item.orderItemProductAttributeValueMappings
                              .sort((a, b) => a.displayOrder - b.displayOrder)
                              .map((att) => (
                                <li key={att.id}>
                                  {att.productAttributeValue.name}
                                </li>
                              ))}
                          </ul>
                        </TableCell>
                        <TableCell>
                          <ul className="extrasPrice">
                            {item.orderItemProductAttributeValueMappings.map(
                              (att) => (
                                <li key={att.id}>
                                  {att.productAttributeValuePriceAdjustment ===
                                  0
                                    ? ""
                                    : money(
                                        att.productAttributeValuePriceAdjustment
                                      )}
                                </li>
                              )
                            )}
                          </ul>
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    ) : null
                  )}
                  <TableRow>
                    <TableCell colSpan={4} />
                  </TableRow>
                  <TableRow className={classes.total}>
                    <TableCell colSpan={2}>{t("subTotal")}</TableCell>
                    <TableCell colSpan={2} align="right">
                      {money(cartList.orderTotal)}
                    </TableCell>
                  </TableRow>
                  {delivery.delivery && (
                    <TableRow className={classes.total}>
                      <TableCell colSpan={2}>{t("deliveryFee")}</TableCell>
                      <TableCell colSpan={2} align="right">
                        {money(cartList.deliveryCharge)}
                      </TableCell>
                    </TableRow>
                  )}
                  {cartList.serviceCharge > 0 && (
                    <TableRow className={classes.total}>
                      <TableCell colSpan={2}>{t("serviceCharge")}</TableCell>
                      <TableCell colSpan={2} align="right">
                        {money(cartList.serviceCharge)}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow className="discount">
                    <TableCell colSpan={2}>{t("discount")}</TableCell>
                    <TableCell colSpan={2} align="right">
                      <span
                        style={{
                          float:
                            information.lang === "Persian" ? "left" : "none",
                        }}
                      >
                        {"-"}&nbsp;
                      </span>
                      {money(cartList.discount)}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.total}>
                    <TableCell colSpan={2}>{t("total")}</TableCell>
                    <TableCell colSpan={2} align="right">
                      {money(
                        cartList.serviceCharge +
                          cartList.orderTotal -
                          cartList.discount +
                          cartList.deliveryCharge
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Hidden>
        </Grid>
        <Grid item container xs={12} xl={6} justifyContent="center">
          <Grid item xs={12} sm={6} xl={10}>
            {!tableOrdering &&
              !(
                information.storeType === "Kiosk" ||
                information.storeType === "OriginKiosk"
              ) && (
                <div className={classes.timePicker}>
                  <TimePicker
                    label={
                      delivery.delivery
                        ? t("deliveryTime")
                        : t("collectionTime")
                    }
                    selectedTime={selectedTime ?? startTime}
                    setSelectedTime={setSelectedTime}
                    minTime={startTime}
                    maxTime={endTime}
                    isDisable={disableTimePicker}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={asap}
                        onChange={handleASAP}
                        name="asap"
                        color="primary"
                      />
                    }
                    label={t("asap")}
                    className={classes.asap}
                  />
                </div>
              )}
            {hasVoucher && (
              <Grid
                item
                xs={12}
                container
                alignItems="baseline"
                className={classes.voucherCodeGrid}
              >
                <h6>{t("discount")}:</h6>
                <TextField
                  onChange={(e) => {
                    setVoucher(e.target.value);
                  }}
                  value={voucher}
                  label={t("voucherCode")}
                  variant="outlined"
                  margin="normal"
                  className="voucherCodeTextField"
                />
                <Button
                  onClick={applyVoucherDiscountHandler}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  {t("applyVoucherCode")}
                </Button>
              </Grid>
            )}

            {!(
              information.storeType === "Kiosk" ||
              information.storeType === "OriginKiosk"
            ) && (
              <div className={classes.deliverNote}>
                <TextField
                  value={note}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                  label={t("note")}
                  multiline
                  rows={2}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={6} xl={10} className="total">
            {!tableOrdering &&
            !(
              information.storeType === "Kiosk" ||
              information.storeType === "OriginKiosk"
            ) ? (
              delivery.delivery ? null : (
                <h4>{t("youCanJustCollectYourOrder")}.</h4>
              )
            ) : null}
            {information.payCash && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  agent.Order.edit(
                    {
                      id: orderId,
                      payType: !tableOrdering
                        ? delivery.delivery
                          ? "CashOnDelivery"
                          : "CashOnCollection"
                        : "Cash",
                      note: note,
                      deliveryTime: selectedTime,
                    },
                    orderId
                  )
                    .then((res) => {
                      dispatch(emptyCart());
                      removeLocalStorage("tableOrdering");
                      history.push(
                        `/ordering/order-status/${cartList.id}/success`
                      );
                    })
                    .catch((e) => {
                      toast.error(e);
                      setLoading(false);
                      history.push(`/`);
                    });
                }}
                className="button"
              >
                {!tableOrdering
                  ? delivery.delivery
                    ? t("payOnDelivery")
                    : t("payOnCollection")
                  : t("payByCash")}
              </Button>
            )}
            {information.evoPayment && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  agent.Payments.RedirectEvoPayment(orderId).then((res) => {
                    dispatch(emptyCart());
                    removeLocalStorage("tableOrdering");
                    window.location.href = res;
                  });
                }}
                className="button"
              >
                Debit Or Credit Card Payment
              </Button>
            )}
            {information.noCheck && information.noCheckMerchantId && (
              <form method="POST" action="https://secure.nochex.com/">
                <input
                  type="hidden"
                  name="merchant_id"
                  value={information.noCheckMerchantId}
                />
                {/* 
                <input
                  type="hidden"
                  name="api_key"
                  value="nvk8c26b2c47536454baea7dc69dceb082c"
                /> */}

                {/* <input type="hidden" name="test_transaction" value="100" /> */}
                <input
                  type="hidden"
                  name="amount"
                  value={
                    cartList.serviceCharge +
                    cartList.orderTotal -
                    cartList.discount +
                    cartList.deliveryCharge
                  }
                />
                <input
                  type="hidden"
                  name="hide_billing_details "
                  value="true"
                />
                <input type="hidden" name="order_id" value={cartList.id} />
                <input
                  type="hidden"
                  name="success_url"
                  value={`${
                    window.location.origin
                  }/ordering/order-status/${orderId}/success/nochex/${
                    selectedTime ? selectedTime.getTime() : null
                  }/${note}`}
                />
                <input
                  type="hidden"
                  name="cancel_url"
                  value={`${
                    window.location.origin
                  }/ordering/order-status/${orderId}/cancel/nochex/${
                    selectedTime ? selectedTime.getTime() : null
                  }/${note}`}
                />
                <input
                  type="hidden"
                  name="callback_url"
                  value={`${
                    window.location.origin
                  }/ordering/order-status/${orderId}/success/nochex/${
                    selectedTime ? selectedTime.getTime() : null
                  }/${note}`}
                />

                {/* <input
                  type="hidden"
                  name="billing_fullname"
                  value="New Customer"
                />
                <input
                  type="hidden"
                  name="billing_address"
                  value="1 Leeds Street"
                />
                <input type="hidden" name="billing_address" value="Leeds" />
                <input type="hidden" name="billing_postcode" value="LS99 1NC" />
                <input
                  type="hidden"
                  name="customer_phone_number"
                  value="01134320987"
                /> */}
                <Button
                  variant="contained"
                  color="primary"
                  className="button"
                  type="submit"
                >
                  Pay on Credit or Debit Card
                </Button>
              </form>
            )}
            {information.paymentSense && (
              <PaymentSense
                orderId={orderId}
                value={
                  cartList.serviceCharge +
                  cartList.orderTotal -
                  cartList.discount +
                  cartList.deliveryCharge
                }
                selectedTime={selectedTime}
                note={note}
              />
            )}
            {information.worldPayClientKey !== "" && information.worldPay && (
              <Grid
                container
                justifyContent="space-around"
                className={classes.cards}
              >
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => {
                    setWorldPayModal(true);
                    setCardType("VISA");
                  }}
                >
                  <img
                    width="100%"
                    style={{ cursor: "pointer" }}
                    src={visa}
                    alt="visa"
                  />
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => {
                    setWorldPayModal(true);
                    setCardType("MSCD");
                  }}
                >
                  <img
                    width="100%"
                    style={{ cursor: "pointer" }}
                    src={masterCard}
                    alt="masterCard"
                  />
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => {
                    setWorldPayModal(true);
                    setCardType("MAES");
                  }}
                >
                  <img
                    width="100%"
                    style={{ cursor: "pointer" }}
                    src={maestro}
                    alt="maestro"
                  />
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => {
                    setWorldPayModal(true);
                    setCardType("AMEX");
                  }}
                >
                  <img
                    width="100%"
                    style={{ cursor: "pointer" }}
                    src={amex}
                    alt="amex"
                  />
                </Button>
              </Grid>
              // <button
              //   onClick={() => {
              //     setWorldPayModal(true);
              //   }}
              //   className="button"
              // >
              //   World Pay
              // </button>
            )}
            {information.payPal && information.payPalClientId !== "" && (
              <PayPalScriptProvider
                options={{
                  "client-id": information.payPalClientId,
                  currency: information.currencyDisplay,
                }}
              >
                <PayPalButtons
                  style={{ layout: "vertical", color: "blue" }}
                  onApprove={(_, actions: any) => {
                    // This function captures the funds from the transaction.
                    return actions?.order
                      ?.capture()
                      ?.then(function (details: any) {
                        // This function shows a transaction success message to your buyer.
                        // alert('Transaction completed by ' + details.payer.name.given_name);
                        setLoading(true);
                        agent.Order.edit(
                          {
                            id: orderId,
                            payType: "PayPal",
                            payPalOrderId: details.id,
                            note: note,
                            deliveryTime: selectedTime,
                          },
                          orderId
                        )
                          .then(() => {
                            dispatch(emptyCart());
                            setLoading(false);
                            history.push(
                              `/ordering/order-status/${cartList.id}/success`
                            );
                          })
                          .catch((error: AxiosResponse) => {
                            agent.Order.edit(
                              {
                                id: orderId,
                                payType: "PayPal",
                                payPalOrderId: details.id,
                                note: note,
                                deliveryTime: selectedTime,
                              },
                              orderId
                            )
                              .then(() => {
                                dispatch(emptyCart());
                                setLoading(false);
                                history.push(
                                  `/ordering/order-status/${cartList.id}/success`
                                );
                              })
                              .catch((error: any) => {
                                setLoading(false);

                                const err = error.data.errors.json;
                                toast.error(
                                  `name=${err.Name},message=${err.Message}`
                                );
                                toast.error(
                                  "if your payment compeleted, call to website manager"
                                );
                                console.log(error);

                                // if (error.status === 409)
                                //   toast.error(
                                //     t("yourPurchaseHadProblemCallToManager")
                                //   );
                              });
                          });
                      });
                  }}
                  createOrder={(_, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                          custom_id: cartList.id,
                          amount: {
                            value: (
                              cartList.orderTotal +
                              cartList.serviceCharge +
                              cartList.deliveryCharge -
                              cartList.discount
                            ).toFixed(2),
                          },
                        },
                      ],
                    });
                  }}
                />
              </PayPalScriptProvider>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default PaymentPage;
