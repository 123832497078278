import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const Footer2Styles = makeStyles((theme: Theme) =>
  createStyles({
    footer2: {
      flip: false,
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#333e48",
      color: "#fff",
      paddingTop: "1rem",

      "@media only screen and (max-width: 600px)": {
        padding: "1rem",
      },
      "& a": {
        color: "#fff",
      },
      "& .footerContainer": {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        padding: "0 0.5rem",
      },

      "& h2": {
        fontSize: "2rem",
        fontWeight: 600,
        lineHeight: "5rem",
        textAlign: "center",
        "@media (max-width: 767px)": {
          fontSize: "2rem",
        },
      },
    },
    footerLinks: {
      display: "flex",
      alignItems: "center",

      "@media only screen and (max-width: 600px)": {
        flexDirection: "column",
      },
      "@media only screen and (max-width: 1920px)": {
        justifyContent: "center",
      },

      "& a": {
        flip: false,
        color: "#fff",
        fontSize: "1rem",
        fontWeight: 600,

        "@media only screen and (min-width: 600px)": {
          flip: false,
        },

        "@media only screen and (min-width: 768px)": {
          fontWeight: 500,
        },

        "&:hover": {
          opacity: 0.9,
        },
        "&:last-child": {
          border: "none",
        },
      },
    },
    footerLinksForMUIChangesOfLanguage: {
      "@media only screen and (min-width: 1280px)": {
        justifyContent: "flex-start",
        paddingLeft: 70,
      },

      "& a": {
        paddingRight: "1rem",
        marginLeft: "1rem",

        "@media only screen and (min-width: 600px)": {
          borderRight: "1px solid #d87230",
        }
      }
    },
    socialLinks: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      "& svg:first-child": {
        marginRight: 20,
      },
    },
    copyRight: {
      color: "#7a7a7a",
      fontStyle: "italic",
      fontSize: "0.9rem",
      "@media (max-width: 767px)": {
        fontSize: "0.6rem",
      },
    },

    carts: {
      display: "flex",
      justifyContent: "center",
      padding: "10px 0",

      [theme.breakpoints.down("xs")]: {
        height: 70,
      },
      [theme.breakpoints.up("sm")]: {
        height: 45,
      },
      [theme.breakpoints.up("md")]: {
        height: 52,
      },
      [theme.breakpoints.up("lg")]: {
        height: 41,
      },
      [theme.breakpoints.up("xl")]: {
        justifyContent: "flex-end",
        height: 51,
        paddingRight: 180,
      },

      "& img": {
        objectFit: "contain",
        marginRight: 10,

        [theme.breakpoints.down("xs")]: {
          margin: "10px 5px",
          width: 40,
        },
        [theme.breakpoints.up("sm")]: {
          width: 40,
        },
        [theme.breakpoints.up("md")]: {
          width: 50,
        },
        [theme.breakpoints.up("lg")]: {
          width: 32,
        },
        [theme.breakpoints.up("xl")]: {
          width: 50,
        },
      },
    },
  })
);
