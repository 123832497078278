import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ShowMoreText from "react-show-more-text";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { Grid, IconButton } from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";

import { ICart } from "../../../../Models/Cart/ICart";
import { ICartRequest } from "../../../../Models/Cart/ICartRequest";
import { IDiscountProducts } from "../../../../Models/product";
import {
  selectMoney,
  selectInformation,
} from "../../../../Stores/informationSlice";
import { addToCart, selectCart } from "../../../../Stores/redisCartSlice";
import { selectDelivery } from "../../../../Stores/deliverySlice";
import { GetStoreId } from "../../../../Services/storeSession";
import agent from "../../../../Services/agent";

import { FreebiesItemRedisStyles } from "./FreebiesItemRedisStyles";
import ChildLoading from "../../../Loading/ChildLoading";

interface IProps {
  product: IDiscountProducts;
  quantity: number;
  showPrice: boolean;
}

const FreebiesItemWithRedisCart: React.FC<IProps> = ({
  product,
  quantity,
  showPrice,
}) => {
  const classes = FreebiesItemRedisStyles();
  const { t } = useTranslation();

  // =======================useSelector=======================
  const dispatch = useDispatch();
  const money = useSelector(selectMoney);
  const information = useSelector(selectInformation);
  const cart = useSelector(selectCart);
  const delivery = useSelector(selectDelivery);

  // ========================useState=========================
  const [loading, setLoading] = useState(false);

  // =========================values==========================
  const storeId = GetStoreId();
  const cartId = cart?.userCart?.id ? cart.userCart.id : undefined;

  // =========================methods=========================
  const handleAddToCart = async () => {
    if (information.openOrder || information.preOrder) {
      const freebiesCartItems = cart?.userCart?.carts.filter(
        (cartItem) => cartItem.isFreebie
      );

      let numberOfFreebiesCartItem = 0;
      freebiesCartItems.map((item) => {
        numberOfFreebiesCartItem += item.quantity;
      });

      if (numberOfFreebiesCartItem >= quantity) {
        toast.warn(t("youJustCanAdd") + quantity + t("freeProductSToTheCart"));
      } else if (storeId) {
        let requestCart: ICartRequest = {
          id: cartId,
          storeId: storeId,
          delivery: delivery?.delivery,
          isFreebie: true,
          productId: product.id,
          duplicateNum: 0,
          quantity: 1,
          note: "",
          editable: false,
          postCode: delivery?.postCode,
          attributes: [],
        };
        setLoading(true);
        await agent.Cart.post(requestCart).then((res: ICart) => {
          setLoading(false);
          if (res) {
            dispatch(addToCart(res));
          } else {
            toast.error(t("somethingWentWrongTryAgain"));
          }
        });
      }
    } else toast.warn(t("onlineOrderClosed"));
  };

  if (loading) return <ChildLoading />;
  // ==========================tsx============================
  return (
    <Grid container className={classes.discountProduct}>
      <Grid item xs={12} md={9} className="naming">
        <span className="name">
          {product.categoryName}:&nbsp;
          {product.name.toLowerCase() === "base" ? "" : product.name}&nbsp;
          {product.mainProductName}
        </span>
        <div className="desc">
          <ShowMoreText
            lines={1}
            more={
              <IconButton color="primary">
                <ExpandMoreRoundedIcon />
              </IconButton>
            }
            less={
              <IconButton color="secondary">
                <ExpandLessRoundedIcon />
              </IconButton>
            }
            className="contentStyle"
            anchorClass="anchorStyle"
            expanded={false}
            width={0}
          >
            <div dangerouslySetInnerHTML={{ __html: product.description }} />
          </ShowMoreText>
        </div>
      </Grid>
      <Grid item xs={12} md={3} className="ordering">
        <span className="price">
          {product.price > 0 && showPrice && money(product.price)}
        </span>
        <IconButton
          size="small"
          onClick={handleAddToCart}
          className={classes.addToCartBtn}
        >
          <div className="whiteBg" />
          <AddCircleRoundedIcon fontSize="large" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default FreebiesItemWithRedisCart;
