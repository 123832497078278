import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnRounded";
import PhoneEnabledRoundedIcon from "@material-ui/icons/PhoneEnabledRounded";
import ShoppingBasketRoundedIcon from "@material-ui/icons/ShoppingBasketRounded";
import {
  Grid,
  Button,
  Hidden,
  Drawer,
  Box,
  IconButton,
  makeStyles,
  Theme,
  createStyles,
  Badge,
} from "@material-ui/core";

import { Navbar2Styles } from "./Navbar2Styles";
import Menu from "./Menu/Menu";
import {
  selectInformation,
  selectMoney,
} from "../../../Stores/informationSlice";
import { selectCart } from "../../../Stores/redisCartSlice";
import agent from "../../../Services/agent";
import { IImageWebSite } from "../../../Models/ImageWebSite";
import Loading from "../../Loading/Loading";

interface IProps {
  name: string;
  telephone: string;
  address: string;
  openOrder: boolean;
  temporaryShopClose: boolean;
}

const Navbar2: React.FC<IProps> = ({
  address,
  name,
  telephone,
  openOrder,
  temporaryShopClose,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [logo, setLogo] = useState<IImageWebSite>();
  const [isLogoLoaded, setIsLogoLoaded] = useState(false);
  const { t } = useTranslation();
  const information = useSelector(selectInformation);
  const cart = useSelector(selectCart);
  const money = useSelector(selectMoney);
  const badge = cart.userCart.carts
    .map((a) => a.quantity)
    ?.reduce((a, b) => a + b, 0);

  const toggle = (open: boolean) => {
    setOpen(open);
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(open);
    };

  // const DepartmentsHandler = withRouter(DepartmentsShow);
  const classes = Navbar2Styles();
  const Styles = makeStyles((theme: Theme) => {
    return createStyles({
      address: {
        display: "flex",
        color: theme.customPalette.fontHeaderColor,
        padding: "3px 5px",
        fontSize: "0.9rem",
        fontWeight: 600,
      },
      tel: {
        display: "flex",
        padding: "3px 5px",
        color: theme.customPalette.fontHeaderColor,
        fontWeight: 600,

        [theme.breakpoints.down("xs")]: {
          display: "none",
        },

        "& p": {
          margin: 0,
          color: theme.customPalette.fontHeaderColor,

          "& span": {
            fontWeight: 600,
          },
        },
      },
      icon: {
        color: theme.customPalette.fontHeaderColor,
      },
    });
  });
  const classes1 = Styles();

  const getLogo = async () => {
    await agent.ImageWebSite.getLogo().then((res) => {
      setIsLogoLoaded(true);
      if (res) {
        setLogo(res);
      }
    });
  };

  useEffect(() => {
    getLogo();
  }, []);

  if (!isLogoLoaded) return <Loading />;

  return (
    <Grid
      container
      justifyContent="center"
      className={classes.navbar2}
      style={{
        backgroundColor: information.headerColor
          ? information.headerColor
          : "#c00",
      }}
      id="top-anchor"
    >
      <Hidden xsDown>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          sm={12}
          className={classes.heading}
        >
          <Grid item className={classes1.address}>
            <LocationOnRoundedIcon className={classes1.icon} fontSize="small" />
            &nbsp;
            <div dangerouslySetInnerHTML={{ __html: address }}></div>
          </Grid>
          <Grid
            item
            className={`${classes1.tel} ${
              information.lang === "Persian" && classes.rtlFlex
            }`}
          >
            <PhoneEnabledRoundedIcon
              className={classes1.icon}
              fontSize="small"
            />
            &nbsp;
            <div dangerouslySetInnerHTML={{ __html: telephone }}></div>&nbsp;
          </Grid>
          <Grid item className={classes.orderStatus}>
            {openOrder && !temporaryShopClose ? (
              <span className="open">{t("open")}</span>
            ) : (
              <span className="closed">{t("closed")}</span>
            )}
          </Grid>
        </Grid>
      </Hidden>
      <Grid item xs={12} className={classes.storeLogo}>
        <NavLink to="/">
          {logo && (
            <img
              src={`${process.env.REACT_APP_IMG_URL}${logo.picPath}`}
              alt={logo.title}
            />
          )}
        </NavLink>
      </Grid>

      <Grid item xs={12} className={classes.storeName}>
        <NavLink to="/">
          <h2>{information.name}</h2>
        </NavLink>
      </Grid>
      <Hidden smUp>
        <Grid
          item
          container
          justifyContent="center"
          xs={12}
          style={{ padding: "5px 0" }}
        >
          <Badge badgeContent={badge} color="secondary">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                var elmnt = document.getElementById("cart");
                elmnt?.scrollIntoView();
              }}
              className={classes.cartBtn}
            >
              <ShoppingBasketRoundedIcon fontSize="small" color="primary" />
              &nbsp;
              {money(cart.userCart.total)}
            </Button>
          </Badge>
        </Grid>
      </Hidden>
      <Hidden xsDown>
        <Grid item sm={12} className={classes.menu}>
          <Menu address={address} tel={telephone} toggleDrawer={toggle} />
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item container xs={12} sm={12} lg={4} xl={4}>
          <div className={classes.menuBtn}>
            <IconButton onClick={toggleDrawer(true)}>
              <MenuRoundedIcon fontSize="large" />
            </IconButton>
            <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
              <Box
                style={{
                  height: "100%",
                  backgroundColor: information.darkTheme ? "#424242" : "#fff",
                }}
              >
                <Menu address={address} tel={telephone} toggleDrawer={toggle} />
              </Box>
            </Drawer>
          </div>
          <div className={classes.orderStatus2 + " " + classes.orderStatus}>
            {openOrder && !temporaryShopClose ? (
              <span className="open">{t("open")}</span>
            ) : (
              <span className="closed">{t("closed")}</span>
            )}
          </div>
          {/* <Grid item xs={12} className={classes.orderNowBtn}>
            <DepartmentsHandler />
          </Grid> */}
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default Navbar2;
