import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import moment from "moment";

import {
  Button,
  ButtonGroup,
  Grid,
  Hidden,
  TextField,
} from "@material-ui/core";

import CustomModal from "../../Modal/CustomModal";
import { history } from "../../..";
import { dateFormat } from "../../../Stores/informationSlice";

import { SubmitBookingStyles } from "./SubmitBookingStyles";

interface IProps {
  receivedDate: Date;
  setShowChecked(set: boolean): void;
  submitReserve(): void;
  selectedTime: Date;
  setSelectedTime(time: Date): void;
  name: string;
  bookedCode: string;
  note: string;
  setName(name: string): void;
  setEmail(email: string): void;
  setPhoneNumber(phoneNumber: string): void;
  setNote(note: string): void;
  modalOpen: boolean;
  setModalOpen(open: boolean): void;
  setTimePickerDisable(state: boolean): void;
  startTime: Date;
  todayTime: Date;
  maxTime: Date;
}

const SubmitBooking: React.FC<IProps> = ({
  receivedDate,
  setShowChecked,
  selectedTime,
  setSelectedTime,
  name,
  bookedCode,
  note,
  setName,
  setEmail,
  setPhoneNumber,
  submitReserve,
  setNote,
  modalOpen,
  setModalOpen,
  setTimePickerDisable,
  startTime,
  todayTime,
  maxTime,
}) => {
  const { t } = useTranslation();
  const classes = SubmitBookingStyles();
  const dateFormatter = useSelector(dateFormat);
  const canBefore = receivedDate.getDate() > new Date().getDate();

  const addFifteen = new Date(selectedTime.getTime() + 15 * 60000);
  const addThirty = new Date(selectedTime.getTime() + 30 * 60000);
  const reduceFifteen = new Date(selectedTime.getTime() - 15 * 60000);
  const reduceThirty = new Date(selectedTime.getTime() - 30 * 60000);
  const now = selectedTime;

  const changeTime = (number: number, time: Date) => {
    setSelectedTime(time);
  };

  const TimesList = [reduceThirty, reduceFifteen, now, addFifteen, addThirty];

  return (
    <>
      <Grid
        item
        xs={12}
        container
        className={classes.root}
        justifyContent="center"
      >
        <CustomModal
          title={t("tableBooked")}
          open={modalOpen}
          handleShow={() => setModalOpen(true)}
          action={
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              onClick={() => history.go(0)}
            >
              {t("backToHome")}
            </Button>
          }
          body={
            <Fragment>
              <p>
                {t("name")}:&nbsp;
                <b>{name}</b>
              </p>
              <p>
                {t("date")}:&nbsp;
                <b>
                  {dateFormatter.format(new Date(receivedDate)).split(",", 1)}
                </b>
              </p>
              <p>
                {t("time")}:&nbsp;
                <b>{moment(selectedTime).format("HH:mm")}</b>
              </p>
              <p>
                {t("yourBookingCode")}:&nbsp;
                <b>{bookedCode}</b>
              </p>
            </Fragment>
          }
          fullWidth
          maxWidth="sm"
          dividers={true}
        />
        <Grid
          item
          xs={12}
          lg={6}
          container
          justifyContent="center"
          className={classes.inputs}
        >
          <TextField
            id="outlined-basic"
            fullWidth
            margin="dense"
            size="small"
            label={t("name")}
            variant="outlined"
            onChange={(event: any) => {
              setName(event.target.value);
            }}
          />
          <TextField
            id="outlined-basic"
            fullWidth
            margin="dense"
            size="small"
            type="email"
            label={t("email")}
            variant="outlined"
            onChange={(event: any) => {
              setEmail(event.target.value);
            }}
          />
          <TextField
            id="outlined-basic"
            fullWidth
            margin="dense"
            size="small"
            label={t("phoneNumber")}
            variant="outlined"
            onChange={(event: any) => {
              setPhoneNumber(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6} container className={classes.inputs}>
          <Grid item xs={12} lg={12} className={classes.time}>
            <Hidden only={["xs", "lg"]}>
              <h5>{t("bookingTime")}:</h5>
            </Hidden>
            <ButtonGroup>
              {TimesList.map((time: Date, index) => (
                <Button
                  key={index}
                  variant={index === 2 ? "contained" : "outlined"}
                  color="secondary"
                  onClick={() => changeTime(index + 1, time)}
                  size="small"
                  disabled={
                    !canBefore
                      ? time < todayTime || time > maxTime
                      : time > maxTime || time < startTime
                  }
                >
                  {time.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })}
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} lg={12} className={classes.note}>
            <TextField
              value={note}
              onChange={(e: any) => setNote(e.target.value)}
              label={t("note")}
              multiline
              rows={5}
              variant="outlined"
              color="primary"
              fullWidth
              margin="dense"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.action}>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          onClick={() => {
            setShowChecked(false);
            setTimePickerDisable(false);
          }}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => submitReserve()}
        >
          {t("submit")}
        </Button>
      </Grid>
    </>
  );
};
export default SubmitBooking;
