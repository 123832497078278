import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const ProductCounterStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonGroup: {
      "& button": {
        padding: 0,
        minWidth: 35,
      },
    },
    qty: {
      fontWeight: 600,
      fontSize: 14,
      "& span": {
        color: theme.palette.text.primary,
      }
    },
  })
);
