import React from "react";
import { Link } from "react-router-dom";
import { ICategory } from "../../../../Models/category";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCategoryId,
  setCategoryId,
} from "../../../../Stores/commonSlice";
import { CategoryMenuItem2Style } from "./CategoryMenuItem2Style";
import { selectInformation } from "../../../../Stores/informationSlice";

interface IProps {
  category: ICategory;
  toggleDrawer?: (open: boolean) => void;
}

const CategoryMenuItem2: React.FC<IProps> = ({ category, toggleDrawer }) => {
  const classes = CategoryMenuItem2Style();
  const categoryId = useSelector(selectCategoryId);
  const dispatch = useDispatch();
  const information = useSelector(selectInformation);

  let categoriesSideBar = <p className="itemName">{category.name}</p>;
  if (information.displayProductsWithImage || information.storeType === "Kiosk") {
    categoriesSideBar = (
      <React.Fragment>
        <img
          src={`${process.env.REACT_APP_IMG_URL}${category.picPath}`}
          alt=""
        />
        <p className="itemName itemName2">{category.name}</p>
      </React.Fragment>
    );
  }
  return (
    <Link
      to={`/ordering/departments/${category.id}`}
      className={
        classes.categoryItem +
        ` ${categoryId === category.id ? "activeLink" : ""}` +
        ` ${(information.displayProductsWithImage || information.storeType === "Kiosk") ?
          "categoryItem2" : ""}`
      }
      onClick={() => {
        dispatch(setCategoryId({ categoryId: category.id }));
        if (toggleDrawer) toggleDrawer(false);
      }}
    >
      {categoriesSideBar}
    </Link>
  );
};

export default CategoryMenuItem2;
