/* eslint-disable eqeqeq */
import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { Grid } from "@material-ui/core";

// import Options from "../../../Options/Options";
import Options from "../../../OptionWithRedisCart/Options";
import RadioProduct from "../RadioProduct2/RadioProduct2";
import agent from "../../../../Services/agent";
import { IMainWithProducts } from "../../../../Models/mainProduct";
import { ISoloProduct } from "../../../../Models/product";
import { IMatch } from "../../../../Models/common";
import ChildLoading from "../../../Loading/ChildLoading";
import { history } from "../../../..";
import {
  selectInformation,
  selectMoney,
} from "../../../../Stores/informationSlice";

import { MainProduct2Styles } from "./MainProduct2Styles";

interface IProps {
  match: IMatch;
}
const MainProduct2: React.FC<IProps> = ({ match }) => {
  const classes = MainProduct2Styles();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  // =======================useSelector=======================
  const information = useSelector(selectInformation);
  const money = useSelector(selectMoney);

  // =========================values==========================
  const mainProductId = match.params.mainProductId;
  const categoryId = match.params.categoryId;
  const productId: string | undefined = match
    ? match.params.productId
    : undefined;
  const duplicateNum: string = match ? match.params.duplicateNum : 1;

  // ========================useState=========================
  const [mainProduct, setMainProduct] = useState<IMainWithProducts>();
  const [product, setProduct] = useState<ISoloProduct>();
  const [loading, setLoading] = useState(false);

  // =========================methods=========================
  const getMainProduct = useCallback(
    async (mainProductId: string, productId?: string) => {
      setLoading(true);
      await agent.MainProduct.mainWithProducts(mainProductId)
        .then((res) => {
          setMainProduct(res);
          if (res.products.length > 0) {
            if (productId) {
              const product = res.products.find((m) => m.id == productId);
              if (product) {
                setProduct(product);
              }
            } else
              setProduct(
                res.products.sort((a, b) => a.displayOrder - b.displayOrder)[0]
              );
          } else {
            history.goBack();
            toast.warn("This Product Is Empty");
          }
        })
        .catch(
          (error) =>
            error.status === 400 &&
            (history.push("/404"), localStorage.setItem("lastPath", "ordering"))
        )
        .finally(() => setLoading(false));
    },
    []
  );

  const handleRadioButton = (productId: string) => {
    const currentProduct = mainProduct?.products.filter(
      (m) => m.id === productId
    );

    if (currentProduct && currentProduct.length > 0) {
      setProduct(currentProduct[0]);
    }
  };

  // =======================useEffect=========================
  useEffect(() => {
    getMainProduct(mainProductId, productId);
    window.scrollTo(0, 0);
  }, [getMainProduct, mainProductId, pathname, productId]);

  if (!mainProduct || !product) return <ChildLoading />;
  // ==========================tsx============================
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.foodName}>
        <h2>
          {mainProduct.name}
          <span>
            {mainProduct.products.length < 2 &&
              mainProduct.products[0].price > 0 &&
              `${money(mainProduct.products[0].price)}`}
          </span>
        </h2>
        <hr />
      </Grid>

      <Grid item xs={12}>
        <p
          className={classes.description}
          dangerouslySetInnerHTML={{
            __html: mainProduct.description,
          }}
        ></p>
      </Grid>

      {(mainProduct.products.length > 1 ||
        information.displayProductsWithImage ||
        information.storeType === "Kiosk") && (
        <Fragment>
          <Grid item xs={12} className={classes.optionRows}>
            {(information.displayProductsWithImage ||
              information.storeType === "Kiosk" ||
              (information.storeType === "Takeaway" &&
                information.theme === "Number3")) && (
              <Grid container xs={12} justifyContent="center">
                <img
                  src={`${process.env.REACT_APP_IMG_URL}${mainProduct.picPath}`}
                  className={classes.optionImage}
                  alt={product.name}
                />
              </Grid>
            )}
            {mainProduct.products.length > 1 && (
              <div className="attrRow crust">
                <Grid item sm={12} md={3} lg={2}>
                  <h6 className="sizeTitle">{t("size")}</h6>
                </Grid>
                <Grid
                  item
                  sm={12}
                  md={9}
                  lg={10}
                  className={classes.optionsRow}
                >
                  {mainProduct.products
                    .sort((a, b) => a.displayOrder - b.displayOrder)
                    .map((product, i) => (
                      <RadioProduct
                        checked={productId ? product.id == productId : i === 0}
                        key={product.id}
                        mainProductName={mainProduct.name}
                        product={product}
                        handleRadioButton={handleRadioButton}
                      />
                    ))}
                </Grid>
              </div>
            )}
          </Grid>
          <hr />
        </Fragment>
      )}

      {product && (
        <Options
          product={product}
          categoryId={categoryId}
          duplicateNum={parseInt(duplicateNum)}
        />
      )}
    </Grid>
  );
};

export default MainProduct2;
