import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const CartStyles = makeStyles((theme: Theme) =>
  createStyles({
    cart: {
      "& .flex": {
        display: "flex",
        "@media (min-width: 0px)": {
          flexDirection: "column",
        },
        "@media (min-width: 600px)": {
          justifyContent: "space-between",
        },
        "@media (min-width: 960px)": {
          justifyContent: "space-around",
        },
        "@media (min-width: 1280px)": {
          justifyContent: "space-between",
        },
        "& p": {
          textAlign: "center",
          fontWeight: 600,
        },
      },
      "& h2": {
        display: "inline-block",
        textAlign: "center",
        color: theme.palette.primary.main,
        padding: 0,
        fontSize: "1.7rem",
        [theme.breakpoints.down("md")]: {
          fontSize: "1.5rem",
        },
        // [theme.breakpoints.up("md")]: {
        //   padding: 0,
        // },
      },
      "& .deliveryCollection": {
        margin: 0,
        fontWeight: 600,
        [theme.breakpoints.down("xs")]: {
          margin: 0,
        },
        [theme.breakpoints.up("md")]: {
          margin: 5,
        },
        "& .desc": {
          fontSize: 12,
          lineHeight: "initial",
        },
      },
      "& .cartBorder": {
        padding: "1rem",
        height: "100%",
        "@media(max-width: 959px)": {
          padding: "0.3rem 0.5rem"
        }
      },
      "& .totalTable": {
        marginTop: 20,

        "& .row": {
          display: "flex",
          justifyContent: "space-between",
          padding: "5px 7px",
          margin: "0 !important"
        },

        "& .total": {
          padding: "10px 7px",
          borderRadius: 5,
          backgroundColor: theme.palette.primary.main,
          "& .bold": {
            color: "#fff",
            fontSize: "1.3rem",
          },
        },
        "& .bold": {
          fontSize: "1.1rem",
          fontWeight: 700,
        },
        "& .discount": {
          color: theme.palette.secondary.main,
        },
      },
      "& .cartBtn": {
        margin: "10px 0",
        padding: "0.5rem",
        fontWeight: 600,
      },
    },
    discountDesc: {
      padding: "5px 5px",

      "& p": {
        color: "#F7D800",
        padding: "5px 0px",
        textAlign: "center",
        fontWeight: 600,
        backgroundColor: "#1A2E4F",
        borderRadius: 5,
      },
    },
  })
);
