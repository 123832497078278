import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { Paper, Button } from "@material-ui/core";
import CachedRoundedIcon from "@material-ui/icons/CachedRounded";

import CartItem from "./CartItem/CartItem";

import { selectInformation, selectMoney } from "../../Stores/informationSlice";
import { selectUser, setToken } from "../../Stores/userSlice";
import { setCategoryId } from "../../Stores/commonSlice";
import { selectCart, setCart } from "../../Stores/redisCartSlice";
import { selectDelivery } from "../../Stores/deliverySlice";
import { selectAllowDelivery } from "../../Stores/alowDelivery";

import { history } from "../..";
import agent from "../../Services/agent";
import { getLocalStorage, removeLocalStorage } from "../../Services/common";
import { ITableOrder, RegisterType } from "../../Models/order";
import Loading from "../Loading/Loading";

import { CartStyles } from "./CartStyles";

interface IProps {
  setDeliveryModal?(status: boolean): void;
}

const Cart: React.FC<IProps> = ({ setDeliveryModal }) => {
  const classes = CartStyles();
  const { t } = useTranslation();

  // =======================useSelector=======================
  const dispatch = useDispatch();
  const cart = useSelector(selectCart);
  const user = useSelector(selectUser);
  const information = useSelector(selectInformation);
  const money = useSelector(selectMoney);
  const delivery = useSelector(selectDelivery);
  const allowDelivery = useSelector(selectAllowDelivery);

  // ========================useState=========================
  const [loading, setLoading] = useState(false);

  // =========================values==========================
  const tableOrdering: ITableOrder = getLocalStorage("tableOrdering");
  const getCart = (id: string | null) => {
    agent.Cart.get(id).then((res) => {
      dispatch(
        setCart({
          isSuccess: res.isSuccess,
          responseText: res.responseText,
          userCart: res.userCart,
        })
      );
    });
  };

  // =========================methods=========================

  const SubmitOrder = async () => {
    if (information.storeType === "Kiosk") {
      await agent.Order.tableOrder({
        cartId: cart?.userCart?.id,
        notes: "",
        numberOfPeople: 0,
        tableNumber: 0,
        orderType: RegisterType.Kiosk,
      })
        .then((res) => {
          dispatch(
            setToken({
              firstName: res.firstName,
              guest: res.guest,
              tableOrder: res.tableOrder,
              lastName: res.lastName,
              roles: ["tableOrder"],
              token: res.token,
            })
          );
          setLoading(false);
          removeLocalStorage("tableOrdering");
          history.push(`/payment/${res.orderId}`);
        })
        .catch((error) => {
          toast.error(t("somethingWentWrongTryAgain"));
          console.log(error);
          setLoading(false);
        });
    } else if (!!tableOrdering) {
      await agent.Order.tableOrder({
        cartId: cart?.userCart?.id,
        notes: "",
        numberOfPeople: tableOrdering.numberOfPeople,
        tableNumber: tableOrdering.tableNumber,
        orderType: RegisterType.TableOrder,
      })
        .then((res) => {
          dispatch(
            setToken({
              firstName: res.firstName,
              guest: res.guest,
              tableOrder: res.tableOrder,
              lastName: res.lastName,
              roles: ["tableOrder"],
              token: res.token,
            })
          );
          setLoading(false);
          removeLocalStorage("tableOrdering");
          history.push(`/payment/${res.orderId}`);
        })
        .catch((error) => {
          toast.error(t("somethingWentWrongTryAgain"));
          console.log(error);
          setLoading(false);
        });
    } else {
      await agent.Order.create({
        cartId: cart.userCart.id,
        orderType: RegisterType.Web,
      })
        .then((res) => {
          setLoading(false);
          history.push(`/payment/${res}`);
        })
        .catch((error) => {
          toast.error(t("somethingWentWrongTryAgain"));
          console.log(error);
          setLoading(false);
        });
    }
  };

  const handleCheckout = () => {
    if (information.storeType === "Kiosk") {
      SubmitOrder();
    } else if (
      delivery.delivery &&
      delivery.minimumOrder > 0 &&
      cart?.userCart?.subTotal - cart?.userCart?.discount <
        delivery.minimumOrder
    ) {
      toast.warn(`${t("minimumOrderIs")} ${money(delivery.minimumOrder)}`);
      return false;
    } else if (
      cart?.userCart?.carts.find((a) => a.productType === "Special") !==
        undefined &&
      cart?.userCart?.carts.find((a) => a.productType === "Food") === undefined
    ) {
      toast.warn(t("youShouldAtLeastSelectOneFoodWithThisOrder"));
      return false;
    } else if (
      (information.openOrder && !information.temporaryShopClose) ||
      information.preOrder
    ) {
      if (user.token && !user.guest && delivery.delivery)
        history.push("/address");
      else if (
        (user.token && !user.guest && !delivery.delivery) ||
        !!tableOrdering
      )
        SubmitOrder();
      else {
        history.push("/guest-ordering");
      }
    } else {
      toast.warn(t("onlineOrderingIsClosed"));
    }
  };

  // =======================useEffect=========================
  useEffect(() => {
    getCart(cart?.userCart?.id);
  }, []);

  if (loading) return <Loading />;
  // ==========================tsx============================
  return (
    <div className={classes.cart} id="cart">
      <Paper className="cartBorder">
        <div className="flex">
          <h2>{t("shoppingCart")}</h2>
          <>
            {!tableOrdering ? (
              information.storeType !== "Kiosk" && (
                <Button
                  disabled={!allowDelivery}
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => setDeliveryModal && setDeliveryModal(true)}
                  className="deliveryCollection"
                >
                  {delivery.delivery ? t("delivery") : t("collection")}&nbsp;
                  <CachedRoundedIcon fontSize="small" />
                </Button>
              )
            ) : (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className="deliveryCollection"
                disableRipple
                onClick={() => history.push("/tableOrdering")}
              >
                {t("tableOrdering")},&nbsp;
                <span className="desc">
                  {t("tableNo")}
                  {tableOrdering.tableNumber}
                </span>
              </Button>
            )}
            {delivery.delivery && delivery.minimumOrder > 0 && (
              <p>
                {t("minimumDeliveryOrderIs")}&nbsp;
                {money(delivery.minimumOrder)}
              </p>
            )}
          </>
        </div>

        {cart?.userCart?.freebieShows?.length > 0 && (
          <div className={classes.discountDesc}>
            {cart.userCart.freebieShows.map((freebie) =>
              information.langDisplay === "fa-IR" ? (
                <p key={freebie.id}>
                  {money(freebie.value)}&nbsp;
                  {"خرید‌کنید"}&nbsp;
                  {freebie.quantity}&nbsp;
                  {"تا"}&nbsp;
                  {t("freebies")}&nbsp;
                  {t("ببرید")}&nbsp;
                </p>
              ) : (
                <p key={freebie.id}>
                  {t("spend")}&nbsp;
                  {money(freebie.value)}&nbsp;
                  {t("get")}&nbsp;
                  {freebie.quantity}&nbsp;
                  {t("freebies")}
                </p>
              )
            )}

            {cart?.userCart?.showFreebie && (
              <Button
                disabled={false}
                onClick={() => {
                  history.push(
                    `/ordering/departments/freebies-products/${cart.userCart.freebieId}`
                  );
                  dispatch(setCategoryId({ categoryId: "" }));
                }}
                variant="contained"
                style={{ backgroundColor: "#F7D800" }}
                fullWidth
                className="cartBtn"
              >
                {t("freebiesProducts")}
              </Button>
            )}
          </div>
        )}

        {cart?.userCart?.carts.length > 0 && (
          <div className="cartItems">
            {cart?.userCart?.carts.map((item) => (
              <CartItem
                key={`${item.productId}${item.duplicateNum}`}
                cartItem={item}
                freebieQuantity={
                  cart.userCart.freebieShows.find(
                    (a) => a.id === cart.userCart.freebieId
                  )?.quantity
                }
              />
            ))}
          </div>
        )}
        <div className="totalTable">
          {cart?.userCart?.total > 0 &&
            delivery.delivery &&
            cart.userCart.deliveryCharge > 0 && (
              <div className="row">
                <span className="bold">{t("deliveryFee")}</span>
                <span className="bold">
                  {money(cart.userCart.deliveryCharge)}
                </span>
              </div>
            )}
          <div className="row">
            <span className="bold">{t("subTotal")}</span>
            <span className="bold">{money(cart?.userCart?.subTotal)}</span>
          </div>
          <div className="row">
            {cart?.userCart?.discount > 0 && (
              <>
                <span className="discount bold">{t("discount")}</span>
                <span className="bold discount">
                  {money(cart?.userCart?.discount)}
                </span>
              </>
            )}
          </div>
          <div className="row total">
            <span className="bold">{t("total")}</span>
            <span className="bold">{money(cart?.userCart?.total)}</span>
          </div>
        </div>
        {cart?.userCart?.carts.length > 0 && (
          <Button
            disabled={
              (!information.openOrder || cart?.userCart?.carts.length < 1) &&
              !information.preOrder
            }
            onClick={handleCheckout}
            variant="contained"
            color="secondary"
            fullWidth
            className="cartBtn"
          >
            {t("checkOut")}
          </Button>
        )}
      </Paper>
    </div>
  );
};
export default Cart;
