import { Fragment } from "react";
import "./childLoading.scss";

const ChildLoading = () => {
  return (
    <Fragment>
      <div className="childDgBlur" />
      <div className="childLoading">
        <div className="childLoader">
          <div className="childLoader--dot"></div>
          <div className="childLoader--dot"></div>
          <div className="childLoader--dot"></div>
          <div className="childLoader--dot"></div>
          <div className="childLoader--dot"></div>
          <div className="childLoader--dot"></div>
          <div className="childLoader--text"></div>
        </div>
      </div>
    </Fragment>
  );
};

export default ChildLoading;
