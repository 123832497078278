import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Button, ButtonGroup } from "@material-ui/core";

import { addToCart, selectCart } from "../../../Stores/redisCartSlice";
import CustomModal from "../../Modal/CustomModal";

import { ProductCounterStyles } from "./ProductCounterStyles";
import { toast } from "react-toastify";
import { GetStoreId } from "../../../Services/storeSession";
import { ICartRequest } from "../../../Models/Cart/ICartRequest";
import { selectInformation } from "../../../Stores/informationSlice";
import agent from "../../../Services/agent";
import { CounterMode } from "../../../Models/Enums/counterMode";

interface IProps {
  id: string;
  quantity: number;
  duplicateNum: number;
}
const ProductCounter: React.FC<IProps> = ({ id, quantity, duplicateNum }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modal, setModal] = useState<boolean>(false);
  const classes = ProductCounterStyles();
  const cart = useSelector(selectCart);
  const information = useSelector(selectInformation);
  const storeId = GetStoreId();
  const cartId = cart?.userCart?.id ? cart.userCart.id : undefined;
  const cartItem = cart.userCart.carts.find((a) => a.productId === id) ?? {
    attributes: [],
    categoryId: "",
    discount: 0,
    discountText: "",
    displayOrder: 0,
    duplicateNum: 0,
    editable: false,
    isFreebie: false,
    mainProductId: "",
    name: "",
    noMoreDiscount: true,
    note: "",
    picPath: "",
    price: 0,
    productId: "",
    productType: "",
    quantity: 0,
    total: 0,
  };
  let requestCart: ICartRequest;
  if (storeId) {
    requestCart = {
      id: cartId,
      storeId: storeId,
      isFreebie: cartItem.isFreebie,
      delivery: cart.userCart.delivery,
      productId: cartItem.productId,
      duplicateNum: cartItem.duplicateNum,
      quantity: cartItem.quantity,
      note: cartItem.note,
      editable: cartItem.editable,
      postCode: cart.userCart.postCode,
      attributes: cartItem.attributes,
    };
  } else toast.warn(t("storeNotFound"));
  const handleQtyChanged = (quantity: number, counterMode?: CounterMode) => {
    if (cartItem.isFreebie && counterMode === CounterMode.Increase) {
      const freebiesCartItems = cart?.userCart?.carts.filter(
        (cartItem) => cartItem.isFreebie
      );

      let numberOfFreebiesCartItem = 0;
      freebiesCartItems.map((item) => {
        numberOfFreebiesCartItem += item.quantity;
      });

      const freebieQuantity =
        cart.userCart.freebieShows.find((a) => a.id === cart.userCart.freebieId)
          ?.quantity ?? 0;
      if (numberOfFreebiesCartItem >= freebieQuantity) {
        toast.warn(
          t("youJustCanAdd") + freebieQuantity + t("freeProductSToTheCart")
        );
        return;
      }
    }
    if (storeId && requestCart) {
      let model = { ...requestCart };
      model.quantity = quantity;
      handleUpdateCart(model);
    } else toast.error(t("somethingWentWrongTryAgain"));
  };
  const handleNoteChanged = (note: string) => {
    if (storeId && requestCart) {
      let model = { ...requestCart };
      model.note = note;
      handleUpdateCart(model);
    } else toast.error(t("somethingWentWrongTryAgain"));
  };

  const handleUpdateCart = async (model: ICartRequest) => {
    if (information.openOrder || information.preOrder) {
      await agent.Cart.post(model).then((res) => {
        if (res) {
          dispatch(addToCart(res));
        } else {
          toast.error(t("somethingWentWrongTryAgain"));
        }
      });
    } else toast.warn(t("onlineOrderClosed"));
  };
  return (
    <Fragment>
      <CustomModal
        title={t("deleteProduct")}
        description={t("thisWillDeleteSelectedProduct")}
        open={modal}
        handleShow={() => setModal(false)}
        action={
          <Fragment>
            <Button
              onClick={() => setModal(false)}
              variant="contained"
              color="secondary"
            >
              {t("no")}
            </Button>
            <Button
              onClick={() => {
                handleQtyChanged(0);
                setModal(false);
              }}
              variant="contained"
              color="primary"
            >
              {t("yes")}
            </Button>
          </Fragment>
        }
      />

      <ButtonGroup size="small" className={classes.buttonGroup}>
        <Button
          aria-label="Increment value"
          onClick={() => {
            if (quantity > 1) {
              handleQtyChanged(cartItem.quantity - 1);
            } else if (quantity === 1) {
              setModal(true);
            }
          }}
        >
          <RemoveIcon />
        </Button>
        <Button disabled className={classes.qty}>
          {quantity}
        </Button>
        <Button
          aria-label="Decrement value"
          onClick={() => {
            handleQtyChanged(cartItem.quantity + 1);
          }}
        >
          <AddIcon />
        </Button>
      </ButtonGroup>
    </Fragment>
  );
};
export default ProductCounter;
