import { PayloadAction } from "@reduxjs/toolkit";

import createGenericSlice from "./GenericSlice";
import { IInformation } from "../Models/information";
import { GenericState } from "../Models/state";
import { accounting } from "../Functions/Format";

export const slice = createGenericSlice({
  name: "information",
  initialState: {
    status: "loading",
    data: {
      aboutUs: "",
      contactUs: "",
      deliveryInformation: "",
      footerContactUs: "",
      freeDeliveryOver: 0,
      minimumOrder: 0,
      name: "Cater Soft",
      openOrder: false,
      openingTime: "",
      payPalClientId: "",
      payPalSecret: "",
      phoneNumber: "",
      privacyPolicy: "",
      returns: "",
      serviceCharge: 0,
      tell: "",
      themeDefault: "",
      currency: "£",
      currencyDisplay: "GBP",
      theme: "",
      symbol: "£",
    },
  } as GenericState<IInformation>,
  reducers: {
    setInformation: (state, action: PayloadAction<IInformation>) => {
      state.status = "finished";
      state.data = action.payload;
    },
  },
});

export const selectInformation = (state: {
  information: { data: IInformation };
}) => state.information.data;

export const selectCurrency = (state: {
  information: { data: IInformation };
}) => state.information.data.currencyDisplay;

export const selectMoney = (state: { information: { data: IInformation } }) => {
  accounting.settings = {
    currency: {
      symbol: state.information.data.symbol ?? "£", // default currency symbol is '$'
      format:
        state.information.data.currencyDisplay === "IRR" ? "%v %s" : "%s%v", // controls output: %s = symbol, %v = value/number (can be object: see below)
      decimal: ".", // decimal point separator
      thousand: ",", // thousands separator
      precision: state.information.data.currencyDisplay === "IRR" ? 0 : 2, // decimal places
      grouping: 0,
    },
    number: {
      precision: 0, // default precision on numbers is 0
      thousand: ",",
      decimal: ".",
      grouping: 0,
    },
  };
  return accounting.formatMoney;
};

export const selectFormat = (state: {
  information: { data: IInformation };
}) => {
  // return accounting.
  return new Intl.NumberFormat(state.information.data.langDisplay, {
    style: "currency",
    currency: state.information.data.currencyDisplay,
  });
};

export const dateFormat = (state: { information: { data: IInformation } }) => {
  return new Intl.DateTimeFormat(state.information.data.langDisplay, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric"
  });

};

export const dateFormatWithWeekdayWithoutTime = (state: { information: { data: IInformation } }) => {
  return new Intl.DateTimeFormat(state.information.data.langDisplay, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    weekday: "long"
  });
};

export const dateFormatWithOnlyTime = (state: { information: { data: IInformation } }) => {
  return new Intl.DateTimeFormat(state.information.data.langDisplay, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true
  });
};

export const { setInformation } = slice.actions;

export default slice.reducer;
