import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const DrawerMenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      minWidth: 200,
      padding: "1rem",
      backgroundColor: theme.palette.background.paper,

      "& a": {
        color: theme.palette.text.primary,
      },
    },
    nested: {
      paddingLeft: theme.spacing(3),
    },
    links: {
      paddingBottom: "1rem",
    },
    info: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      [theme.breakpoints.down("xs")]: {
        width: 180,
      },
      [theme.breakpoints.down("md")]: {
        width: 210,
      },
      "& p": {
        margin: 0,
        overflowWrap: "break-word",
        color: theme.palette.text.primary,
      },

      "& hr": {
        width: "100%",
        margin: "10px 0",
      },

      "& .addApp": {
        "& button": {
          margin: "10px 0",
        },
      },
    },
  })
);
