import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const _404PageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("sm")]: {
        height: "60vh",
      },
    },
    paper: {
      padding: 10,
      "& h2, p": {
        textAlign: "center",
      },
      "& h2": {
        color: theme.palette.primary.main,
      },
      "& p": {
        fontSize: "1.3rem",
      },
    },
    links: {
      marginTop: 20,
      padding: 10,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",

      [theme.breakpoints.down("xs")]: {
        flexWrap: "wrap",
        "& a": {
          minWidth: 110,
          padding: "0 10px",
        },
      },

      [theme.breakpoints.up("sm")]: {
        width: "50vw",
      },

      "& a": {
        textAlign: "center",
      },

      "& h6": {
        display: "block",
        textAlign: "center",
      },
    },
  })
);
