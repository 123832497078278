import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const CategoryMenuItem2Style = makeStyles((theme: Theme) =>
  createStyles({
    categoryItem: {
      borderBottom: "1px solid gray",
      padding: 4,
      cursor: "pointer",
      "&:last-child": {
        borderBottom: "none",
      },
      "&.categoryItem2": {
        borderBottom: "none !important",
      },
      "& .itemName": {
        color: theme.palette.text.primary,
        fontSize: "1.3rem",
        fontWeight: 600,
        textAlign: "center",
        borderRadius: 2,
        margin: "auto 0",
        transitionDuration: "0.2s",

        "&&:hover": {
          transform: "scale(1.1)",
        },
        // "@media only screen and (min-width: 768px)": {
        //   fontSize: "1.1rem",
        // },
        "@media (min-width: 0)": {
          fontSize: "0.8rem",
        },
        "@media (min-width: 600px)": {
          fontSize: "1rem",
        },
        "@media (min-width: 960px)": {
          fontSize: "1.1rem",
        },
        "@media (min-width: 1280px)": {
          fontSize: "1.2rem",
        },

        "@media (min-width: 1920px)": {
          fontSize: "1.5rem",
        },
      },
      "& .itemName2": {
        color: "#fff", //must be get from theme
      },
      "&.activeLink .itemName": {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        "&:hover": {
          transform: "none",
        },
      },
      "&:hover": {
        transform: "scale(1.02)",
      },
      "& img": {
        display: "block",
        margin: "0 auto",
        objectFit: "contain",

        "@media (min-width: 0)": {
          width: 60,
          height: 60,
        },
        "@media (min-width: 600px)": {
          width: 80,
          height: 80,
        },
        "@media (min-width: 960px)": {
          width: 80,
          height: 80,
        },
        "@media (min-width: 1280px)": {
          width: 100,
          height: 100,
        },
        "@media (min-width: 1920px)": {
          width: 120,
          height: 120,
        },
      },
    },
  })
);
