import React, { useState, useEffect } from "react";
import "./profileAccount.scss";
import agent from "../../../Services/agent";
import { IUserEdit, UserDetailToUSerEdit } from "../../../Models/user";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@material-ui/core";
import { FORM_ERROR } from "final-form";
import { Form, Field } from "react-final-form";
import { toast } from "react-toastify";
import ErrorMessage from "../../../utils/ErrorMessage";
import ProductTextInput from "../../../utils/ProductTextInput";
import { combineValidators, isRequired } from "revalidate";

const ProfileAccount = () => {
  const { t } = useTranslation();

  const validate = combineValidators({
    firstName: isRequired({ message: t("required") }),
    lastName: isRequired({ message: t("required") }),
  });
  const [user, setUser] = useState<IUserEdit>({
    email: "",
    firstName: "",
    id: "",
    lastName: "",
    phoneNumber: "",
    userName: "",
    rolesId: [],
  });
  const getUser = async () => {
    await agent.User.userDetail().then((res) =>
      setUser(UserDetailToUSerEdit(res))
    );
  };
  useEffect(() => {
    getUser();
  }, []);
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <h3 className="profileTitle">{t("myPersonalInformation")}</h3>
        <p className="profileDescribe">{t("underTheProfileSection")}</p>
      </Grid>
      <Form
        initialValues={user}
        validate={validate}
        onSubmit={(values: IUserEdit) => {
          agent.User.modify(values, user.id)
            .then((res) => {
              toast.success(t("submittedSuccessfully"));
            })
            .catch((error) => {
              return {
                [FORM_ERROR]: error,
              };
            });
        }}
        render={({
          handleSubmit,
          invalid,
          pristine,
          submitting,
          form,
          submitError,
          dirtySinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            {/* {submitError && submitError.data.errors && !dirtySinceLastSubmit && ( */}
            {submitError && !dirtySinceLastSubmit && (
              <ErrorMessage text="Error(s)" error={submitError} />
            )}
            <Grid container justifyContent="center">
              <Grid item xs={12} md={6}>
                <Field
                  label={t("firstName")}
                  name="firstName"
                  component={ProductTextInput}
                  placeholder={t("firstName")}
                />
                <Field
                  label={t("lastName")}
                  name="lastName"
                  component={ProductTextInput}
                  placeholder={t("lastName")}
                />
                <Field
                  label={t("phoneNumber")}
                  name="phoneNumber"
                  component={ProductTextInput}
                  placeholder={t("phoneNumber")}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} container justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={(invalid && !dirtySinceLastSubmit) || submitting}
                >
                  {t("submit")}
                </Button>
              </Grid>
            </Grid>
            {/* <pre dir="ltr">{JSON.stringify(form.getState(), null, 2)}</pre> */}
          </form>
        )}
      />
    </Grid>
  );
};

export default ProfileAccount;
