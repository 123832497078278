import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { TimePickerStyles } from "./TimePickerStyles";

interface IProps {
  isDisable?: boolean;
  label?: string;
  minTime: Date;
  maxTime?: Date;
  selectedTime: Date;
  setSelectedTime(date: Date): void;
  step?: number;
  timeFormat?: string;
}

const TimePicker: React.FC<IProps> = ({
  isDisable,
  label,
  minTime,
  maxTime = new Date(0, 0, 0, 22, 30),
  selectedTime,
  setSelectedTime,
  step = 15,
  timeFormat,
}) => {
  const classes = TimePickerStyles();

  return (
    <div
      className={classes.timePickerContainer}
      style={{ position: "relative" }}
    >
      {label && <h6 style={{ margin: "0 10px 0 10px" }}>{label}:</h6>}
      <DatePicker
        selected={selectedTime}
        onChange={(date: Date) => {
          setSelectedTime(date);
        }}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={step}
        timeCaption=""
        dateFormat="HH:mm"
        minTime={minTime}
        maxTime={maxTime}
        disabled={isDisable}
        className={classes.timePicker}
        timeFormat={timeFormat}
      />
      <ArrowDropDownIcon className={classes.timePickerIcon} />
    </div>
  );
};

export default TimePicker;
