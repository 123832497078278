import React, { useEffect, useState } from "react";
import { PaymentSenseStyles } from "./PaymentSenseStyles";
import ScriptTag from "react-script-tag";
import agent from "../../Services/agent";
import { useSelector } from "react-redux";
import { selectMoney } from "../../Stores/informationSlice";
import { useTranslation } from "react-i18next";

interface IProps {
  value: number;
  orderId: string;
  selectedTime: Date | null;
  note: string;
}

const PaymentSense: React.FC<IProps> = ({
  value,
  orderId,
  selectedTime,
  note,
}) => {
  const { t } = useTranslation();
  const classes = PaymentSenseStyles();
  const [accessToken, setAccessToken] = useState("");
  const [showPayment, setShowPayment] = useState(false);
  const money = useSelector(selectMoney);

  const getAccessToken = (orderId: string) => {
    agent.Payments.getPaymentSenseToken(orderId)
      .then((res) => {
        setAccessToken(res.id);
        if (res.id != null && res.id.length > 0) setShowPayment(true);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getAccessToken(orderId);
  }, [orderId]);
  return (
    <>
      {showPayment && (
        <form
          className={classes.root}
          id="paymentSenseForm"
          action={`/ordering/order-status/${orderId}/paymentSense/${accessToken}/${
            selectedTime ? selectedTime.getTime() : null
          }/${note}`}
          method="get"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ScriptTag
            type="text/javascript"
            src={`${process.env.REACT_APP_PAYMENTSENSE_JS}`}
            data-amount={Math.floor(value * 100)}
            data-access-token={accessToken}
            data-currency-code="826"
            data-description={`Payment of ${money(value)} GBP`}
            data-button-text="Debit Or Credit Card Payment"
            data-submit-button-text={t("pay") + " " + money(value)}
            className="connect-checkout-btn"
            data-display-address={false}
            // data-display-email={true}
          />
        </form>
      )}
    </>
  );
};

export default PaymentSense;
