import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const Carousel2Styles = makeStyles((theme: Theme) =>
  createStyles({
    carousel2: {
      position: "relative",
      padding: '1rem',
      "& img": {
        width: "100%",
        objectFit: "cover",
        borderRadius: 5,
      },
       "& img, .carouselHeight": {
         "@media only screen and (max-width: 600px)": {
           height: 500,
         },

         "@media only screen and (min-width: 600px)": {
           height: 800,
         },

         "@media only screen and (min-width: 960px)": {
           height: 1120,
         },

         "@media only screen and (min-width: 1280px)": {
           height: 580,
         },

         "@media only screen and (min-width: 1920px)": {
           height: 630,
         },
       },
    },
  })
);
