import { ITextSlider } from "./../Models/textSlider";
import { IPAMChangePrice } from "../Models/productAttributeMapping";
import { IAutoOrdering } from "../Models/autoOrdering";
import {
  IApplyVoucherDiscount,
  IChangeStatus,
  IOrdersList,
  IReorder,
  ITableOrder,
  ITableOrderRes,
} from "../Models/order";
import { IMainProduct, IMainWithProducts } from "../Models/mainProduct";
import { IChangeProductPrices } from "../Models/product";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import {
  IOrderPagedCollection,
  IPagedCollection,
} from "../Models/pagedCollection";
import {
  ISimpleProduct,
  IModifyProduct,
  IProductAdminList,
  IProductSearch,
  ISoloProduct,
} from "../Models/product";
import {
  ILogin,
  IUser,
  IRegister,
  IUserDetail,
  IChangePassword,
  IUserList,
  IUserEdit,
  IResetPassword,
} from "../Models/user";
import { SelectList } from "../Models/selectList";
import {
  ICategoryAdminList,
  IModifyCategory,
  ICategory,
} from "../Models/category";
import { IAddress, IModifyAddress } from "../Models/address";
import {
  IOrderSubmit,
  IOrderDisplay,
  IOrderEdit,
  IOrderList,
  IOrderAdminList,
  IOrderDetail,
} from "../Models/order";
import { ICloseTemporary, IInformation } from "../Models/information";
import { ICreateImageWebSite, IImageWebSite } from "../Models/ImageWebSite";
import { IRoleList } from "../Models/role";
import {
  IProductAttribute,
  IProductAttributeModify,
} from "../Models/productAttribute";
import {
  IProductAttributeMapping,
  IProductAttributeMappingModify,
} from "../Models/productAttributeMapping";
import {
  IProductAttributeValue,
  IProductAttributeValueModify,
  IChangeProductAttributeValuePrice,
} from "../Models/productAttributeValue";
import { ISubscription } from "../Models/subscrption";
import { ILanguage } from "../Models/language";
import {
  IShopDetail,
  IPrintSetting,
  IDeliveryStartEndTime,
} from "../Models/setting";
import { ICron, IOpenTimes } from "../Models/hangFire";
import { IGuestRegister, IUserTokenWithOrderId } from "../Models/guest";
import { GetStoreId } from "./storeSession";
import {
  IBookingTables,
  IGetBookingTable,
  IModifyBookingTable,
  IModifyTable,
  ITables,
} from "../Models/tables";
import { getUser } from "./common";
import { ICategoryReport, IProductsSizeReport } from "../Models/reports";
import { IAssignOrder } from "../Models/drivers";
import {
  IDeliveryCharge,
  IDeliveryChargeValue,
  IModifyDeliveryCharge,
} from "../Models/deliveryCharge";
import { IModifyTierPrice, ITierPrice } from "../Models/tierPrices";
import { IPSAccessToken } from "../Models/paymentsense";
import {
  ICreateFreebies,
  IFreebies,
  IFreebiesProducts,
} from "../Models/freebies";
import { IDeliveryTime, IModifyDeliveryTime } from "../Models/deliveryTime";
import { ICartRequest } from "../Models/Cart/ICartRequest";
import { ICart } from "../Models/Cart/ICart";

axios.interceptors.request.use(
  async (config) => {
    const uri = GetStoreId();
    if (uri) {
      config.baseURL = `${process.env.REACT_APP_API_URL}/api/${uri}`;
      // config.baseURL = `https://localhost:5001/api/${uri}`;
    } else {
      window.location.reload();
    }
    const token = getUser();
    if (token.token.length > 0)
      config.headers.Authorization = `Bearer ${token.token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, (error) => {
  if (!error.response) {
    throw error;
  }
  const { status, data, config } = error.response;
  const ExceptedError = status && status >= 400 && status < 500;
  if (!ExceptedError) {
    toast.error("server error. for more details open console");
    console.log("error mess: ", data, config);
  }
  if (status === 404) {
    window.location.href = "/notFound";
  }
  if (status === 401 || status === 403) {
    window.localStorage.removeItem("account");
    window.location.href = "/login";
  }
  throw error.response;
});

const responseBody = (response: AxiosResponse) => response.data;
const requests = {
  get: (url: string) => axios.get(url).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  del: (url: string) => axios.delete(url).then(responseBody),
};

const Products = {
  list: (
    limit: number = 10,
    offset: number = 0,
    search: string = "",
    sort: string = "UpdatedOn desc"
  ): Promise<IPagedCollection<ISimpleProduct>> =>
    requests.get(
      `/products?limit=${limit}${
        offset === 0 ? "" : `&offset=${offset}`
      }${`&OrderBy=${sort}`}${search}`
    ),
  search: (
    limit: number = 10,
    offset: number = 0,
    sort: string = "CreatedOn desc",
    search: string = ""
  ): Promise<IPagedCollection<IProductSearch>> =>
    requests.get(
      `/products/SearchProducts?limit=${limit}${
        offset === 0 ? "" : `&offset=${offset}`
      }${`&OrderBy=${sort}`}${search}`
    ),
  productsByCategoryId: (
    id: string,
    limit: number = 16,
    offset: number,
    search?: string,
    sort: string = "UpdatedOn desc"
  ): Promise<IPagedCollection<ISimpleProduct>> =>
    requests.get(
      `/products/ProductsByCategoryId/${id}?limit=${limit}${
        offset === 0 ? "" : `&offset=${offset}${`&OrderBy=${sort}`}`
      }${search}`
    ),
  productsByBaseProductId: (
    id: string,
    limit: number = 16,
    offset: number,
    search?: string,
    sort: string = "UpdatedOn desc"
  ): Promise<IPagedCollection<ISimpleProduct>> =>
    requests.get(
      `/products/ProductsByBaseProductId/${id}?limit=${limit}${
        offset === 0 ? "" : `&offset=${offset}${`&OrderBy=${sort}`}`
      }${search}`
    ),
  hotOffers: (): Promise<ISimpleProduct[]> =>
    requests.get("/products/HotOffers"),
  weekTopPicks: (): Promise<ISimpleProduct[]> =>
    requests.get("/products/WeekTopPicks"),
  create: (form: IModifyProduct): Promise<number> =>
    requests.post("/products", form),
  edit: (form: IModifyProduct, id: string): Promise<number> =>
    requests.put(`/products/${id}`, form),
  changePrices: (form: IChangeProductPrices): Promise<number> =>
    requests.put("/products/ChangePrices", form),
  getById: (id: string): Promise<IModifyProduct> =>
    requests.get(`/products/${id}`),
  solo: (id: string): Promise<ISoloProduct> =>
    requests.get(`/products/Solo/${id}`),
  delete: (id: string): Promise<{}> => requests.del(`/products/${id}`),
  adminList: (
    limit: number = 10,
    offset: number = 0,
    sort: string = "",
    search: string = ""
  ): Promise<IPagedCollection<IProductAdminList>> =>
    requests.get(
      `/products/AdminList?limit=${limit}${
        offset === 0 ? "" : `&offset=${offset}`
      }${`&OrderBy=${sort}`}${search}`
    ),
};
const User = {
  login: (form: ILogin): Promise<IUser> => requests.post("/Users/Login", form),
  register: (form: IRegister): Promise<IUser> =>
    requests.post("/Users/Register", form),
  modify: (form: IUserEdit, id?: string): Promise<{}> => {
    if (id) return requests.put("/Users/Edit", form);
    else return requests.post("/Users/Create", form);
  },
  languages: (): Promise<ILanguage[]> => requests.get("/Users/Languages"),
  refreshToken: (): Promise<IUser> => requests.get("/Users/RefreshToken"),
  userDetail: (id?: string): Promise<IUserDetail> =>
    requests.get(`/Users/UserDetail${id ? `?id=${id}` : ""}`),
  changePassword: (form: IChangePassword): Promise<{}> =>
    requests.post("/Users/ChangePassword", form),
  allUser: (
    limit: number = 10,
    offset: number = 0,
    sort: string = "",
    search: string = "",
    roleId?: string
  ): Promise<IPagedCollection<IUserList>> =>
    requests.get(
      `/Users/AllUsers?${roleId ? `roleId=${roleId}&` : ""}limit=${limit}${
        offset === 0 ? "" : `&offset=${offset}`
      }${`&OrderBy=${sort}`}${search}`
    ),
  allCustomer: (
    limit: number = 10,
    offset: number = 0,
    sort: string = "",
    search: string = ""
  ): Promise<IPagedCollection<IUserList>> =>
    requests.get(
      `/Users/AllCustomers?limit=${limit}${
        offset === 0 ? "" : `&offset=${offset}`
      }${`&OrderBy=${sort}`}${search}`
    ),
  resetPasswordRequest: (email: string): Promise<{}> =>
    requests.get(`/Users/TokenResetPassword?email=${email}`),
  resetPassword: (form: IResetPassword): Promise<IUser> =>
    requests.post("/Users/ResetPassword", form),
};
const Categories = {
  create: (form: FormData): Promise<{}> => requests.post("/Categories", form),
  edit: (form: FormData, id: string): Promise<{}> =>
    requests.put(`/Categories/${id}`, form),
  getById: (id: string): Promise<IModifyCategory> =>
    requests.get(`/Categories/${id}`),

  listById: (id?: string): Promise<ICategory[]> =>
    requests.get(`/Categories/CategoriesListById/${id ? `?id=${id}` : ""}`),
  selectList: (
    limit: number = 300,
    offset: number = 0,
    sort: string = "",
    search: string = ""
  ): Promise<IPagedCollection<SelectList<string>>> =>
    requests.get(
      `/Categories/SelectList?limit=${limit}${
        offset === 0 ? "" : `&offset=${offset}`
      }${`&OrderBy=${sort}`}${search}`
    ),
  adminList: (
    limit: number = 10,
    offset: number = 0,
    sort: string = "",
    search: string = ""
  ): Promise<IPagedCollection<ICategoryAdminList>> =>
    requests.get(
      `/Categories/AdminList?limit=${limit}${
        offset === 0 ? "" : `&offset=${offset}`
      }${`&OrderBy=${sort}`}${search}`
    ),
  delete: (id: string): Promise<{}> => requests.del(`/Categories/${id}`),
  homePageCategories: (): Promise<ICategory[]> =>
    requests.get("/Categories/HomePageCategories/"),
};
const ProductAttribute = {
  create: (form: IProductAttributeModify): Promise<{}> =>
    requests.post("/ProductAttributes", form),
  edit: (form: IProductAttributeModify, id: string): Promise<{}> =>
    requests.put(`/ProductAttributes/${id}`, form),
  adminList: (
    limit: number = 10,
    offset: number = 0,
    search: string = ""
  ): Promise<IPagedCollection<IProductAttribute>> =>
    requests.get(
      `/ProductAttributes?limit=${limit}${
        offset === 0 ? "" : `&offset=${offset}`
      }${search}`
    ),
  getById: (id: string): Promise<IProductAttribute> =>
    requests.get(`/ProductAttributes/${id}`),
  delete: (id: string): Promise<{}> => requests.del(`/ProductAttributes/${id}`),
  selectList: (
    limit: number = 300,
    offset: number = 0,
    sort: string = "",
    search: string = ""
  ): Promise<IPagedCollection<SelectList<string>>> =>
    requests.get(
      `/ProductAttributes/SelectList?limit=${limit}${
        offset === 0 ? "" : `&offset=${offset}`
      }${`&OrderBy=${sort}`}${search}`
    ),
};
const ProductAttributeMapping = {
  create: (form: IProductAttributeMappingModify): Promise<{}> =>
    requests.post("/ProductAttributeMappings", form),
  edit: (form: IProductAttributeMappingModify): Promise<{}> =>
    requests.put(`/ProductAttributeMappings/${form.id}`, form),
  changePrice: (form: IPAMChangePrice): Promise<{}> =>
    requests.post(
      "/ProductAttributeMappings/ProductAttributeMappingChangePrice",
      form
    ),
  getByProductId: (
    productId: string,
    limit: number = 10,
    offset: number = 0,
    search: string = ""
  ): Promise<IPagedCollection<IProductAttributeMapping>> =>
    requests.get(
      `/ProductAttributeMappings/ProductAttributeMappingByProductId/${productId}?limit=${limit}${
        offset === 0 ? "" : `&offset=${offset}`
      }${search}`
    ),
  getById: (id: string): Promise<IProductAttributeMappingModify> =>
    requests.get(`/ProductAttributeMappings/${id}`),
  delete: (id: string): Promise<{}> =>
    requests.del(`/ProductAttributeMappings/${id}`),
  copy: (id: string): Promise<number> =>
    requests.post("/ProductAttributeMappings/CopyProductAttributeMapping", {
      id: id,
    }),
};
const ProductAttributeValue = {
  create: (form: IProductAttributeValueModify): Promise<{}> =>
    requests.post("/ProductAttributeValues", form),
  edit: (form: IProductAttributeValueModify, id: string): Promise<{}> =>
    requests.put(`/ProductAttributeValues/${id}`, form),
  getByProductAttributeMappingId: (
    productId: string,
    limit: number = 10,
    offset: number = 0,
    search: string = ""
  ): Promise<IPagedCollection<IProductAttributeValue>> =>
    requests.get(
      `/ProductAttributeValues/ProductAttributeValueByProductAttributeMappingId/${productId}?limit=${limit}${
        offset === 0 ? "" : `&offset=${offset}`
      }${search}`
    ),
  getById: (id: string): Promise<IProductAttributeValue> =>
    requests.get(`/ProductAttributeValues/${id}`),
  delete: (id: string): Promise<{}> =>
    requests.del(`/ProductAttributeValues/${id}`),
  changePrice: (form: IChangeProductAttributeValuePrice): Promise<number> =>
    requests.put("/ProductAttributeValues/ChangePrice", form),
  selectList: (
    productAttributeMappingId: string,
    limit: number = 300,
    offset: number = 0,
    sort: string = "",
    search: string = ""
  ): Promise<IPagedCollection<SelectList<string>>> =>
    requests.get(
      `/ProductAttributeValues/SelectList?productAttributeMappingId=${productAttributeMappingId}&limit=${limit}${
        offset === 0 ? "" : `&offset=${offset}`
      }${`&OrderBy=${sort}`}${search}`
    ),
};
const Address = {
  create: (form: IModifyAddress): Promise<number> =>
    requests.post("/Addresses", form),
  edit: (form: IModifyAddress, id: string): Promise<{}> =>
    requests.put(`/Addresses/${id}`, form),
  CustomerAddresses: (id?: string): Promise<IAddress[]> =>
    requests.get(
      `/Addresses/GetCustomerAddresses${id ? `?CustomerId=${id}` : ""}`
    ),
  getById: (id: string): Promise<IModifyAddress> =>
    requests.get(`/Addresses/${id}`),
  delete: (id: string): Promise<{}> => requests.del(`/Addresses/${id}`),
};
const Order = {
  create: (form: IOrderSubmit): Promise<string> =>
    requests.post("/Orders", form),
  detail: (id: string): Promise<IOrderDetail> =>
    requests.get(`/Orders/Detail/${id}`),
  edit: (form: IOrderEdit, id: string): Promise<{}> =>
    requests.put(`/Orders/${id}`, form),
  solo: (id: string): Promise<IOrderDisplay> => requests.get(`/Orders/${id}`),
  userOrders: (
    limit: number,
    offset: number = 0,
    id?: string
  ): Promise<IPagedCollection<IOrderList>> =>
    requests.get(
      `/Orders/UserOrders${
        id ? `?id=${id}` : "?"
      }limit=${limit}&offset=${offset}`
    ),
  list: (
    limit: number,
    offset: number = 0,
    sort: string = "",
    search: string = "",
    // userId?: string
    or: boolean = false
  ): Promise<IOrderPagedCollection<IOrdersList>> =>
    requests.get(
      `/Orders/List?limit=${limit}&offset=${offset}&${sort}${search}&or=${or}`
    ),
  adminList: (
    limit: number = 10,
    offset: number = 0,
    sort: string = "",
    search: string = "",
    userId?: string
  ): Promise<IPagedCollection<IOrderAdminList>> =>
    requests.get(
      `/Orders/AdminList?${userId ? `&userId=${userId}&` : ""}limit=${limit}${
        offset === 0 ? "" : `&offset=${offset}`
      }${`&OrderBy=${sort}`}${search}`
    ),
  delete: (id: string): Promise<{}> => requests.del(`/Orders/${id}`),
  changeStatus: (form: IChangeStatus): Promise<{}> =>
    requests.put(`/Orders/ChangeStatusOrder/${form.id}`, form),
  CreateGuestOrder: (form: IGuestRegister): Promise<IUserTokenWithOrderId> =>
    requests.post("/Orders/CreateGuestOrder", form),
  reorder: (form: IReorder): Promise<{}> =>
    requests.post("/Orders/ReOrder", form),
  tableOrder: (form: ITableOrder): Promise<ITableOrderRes> =>
    requests.post("/Orders/CreateTableOrder", form),
  applyVoucherDiscount: (
    form: IApplyVoucherDiscount
  ): Promise<IApplyVoucherDiscount> =>
    requests.post("/Orders/ApplyVoucherDiscount", form),
};
const Information = {
  edit: (form: IInformation): Promise<{}> => requests.put("/Information", form),
  get: (): Promise<IInformation> => requests.get("/Information"),
  getEnumValues: (name: string): Promise<SelectList<string>[]> =>
    requests.get(`/Information/GetEnumValues/${name}`),
  temporaryClose: (form: ICloseTemporary): Promise<{}> =>
    requests.put("/Settings/TemporaryClose", form),
};
const Pdf = {
  getOrderPdf: (id: string): Promise<{}> =>
    requests.get(`/PdfCreators/OrderPdf/${id}`),
  posOrderPdf: (id: string): Promise<{}> =>
    requests.get(`/PdfCreators/PosOrderPdf/${id}`),
};
const ImageWebSite = {
  create: (form: ICreateImageWebSite): Promise<{}> => {
    const formData = new FormData();
    formData.append("file", form.file);
    formData.append("displayOrder", form.displayOrder.toString());
    formData.append("cols", form.cols.toString());
    formData.append("rows", form.rows.toString());
    formData.append("title", form.title);
    formData.append("type", form.type.toString());
    return requests.post("/ImageWebSites", formData);
  },
  delete: (id: string): Promise<{}> => requests.del(`/ImageWebSites/${id}`),
  getMiddleMainPage: (): Promise<IImageWebSite> =>
    requests.get("/ImageWebSites/GetMiddleMainPage"),
  getLogo: (): Promise<IImageWebSite> => requests.get("/ImageWebSites/GetLogo"),
  getFavicon: (): Promise<IImageWebSite> =>
    requests.get("/ImageWebSites/GetFavicon"),
  getSliders: (): Promise<IImageWebSite[]> =>
    requests.get("/ImageWebSites/GetSliders"),
  list: (
    limit: number = 10,
    offset: number = 0,
    sort: string = "",
    search: string = ""
  ): Promise<IPagedCollection<IImageWebSite>> =>
    requests.get(
      `/ImageWebSites?limit=${limit}${
        offset === 0 ? "" : `&offset=${offset}`
      }${`&OrderBy=${sort}`}${search}`
    ),
  getLandingPageImages: (): Promise<IImageWebSite[]> =>
    requests.get("/ImageWebSites/GetLandingPageImages"),
  getRating: (): Promise<IImageWebSite> =>
    requests.get("/ImageWebSites/GetRating"),
};
const Role = {
  getRoles: (
    limit: number = 100,
    offset: number = 0,
    sort: string = "",
    search: string = ""
  ): Promise<IPagedCollection<IRoleList>> =>
    requests.get(
      `/Roles/GetRoles?limit=${limit}${
        offset === 0 ? "" : `&offset=${offset}`
      }${`&OrderBy=${sort}`}${search}`
    ),
  selectListRole: (
    limit: number = 100,
    offset: number = 0,
    sort: string = "",
    search: string = ""
  ): Promise<IPagedCollection<IRoleList>> =>
    requests.get(
      `/Roles/SelectListRoles?limit=${limit}${
        offset === 0 ? "" : `&offset=${offset}`
      }${`&OrderBy=${sort}`}${search}`
    ),
};
const Notification = {
  GetVapidPublicKey: (): Promise<string> => requests.get("/Push"),
  send: (): Promise<{}> => requests.get("/Push/Send"),
  unSubscribe: (subscription: any): Promise<string> =>
    requests.post("/Push/Unsubscribe", subscription),
  subscribe: (subscription: any): Promise<ISubscription> =>
    requests.post("/Push", subscription),
};
const MainProduct = {
  list: (
    categoryId?: string,
    limit: number = 10,
    offset: number = 0,
    search: string = "",
    sort: string = ""
  ): Promise<IPagedCollection<IMainProduct>> =>
    requests.get(
      `/MainProducts?limit=${limit}&${
        categoryId ? `categoryId=${categoryId}&` : ""
      }${offset === 0 ? "" : `offset=${offset}&`}${sort}${search}`
    ),
  adminList: (
    categoryId?: string,
    limit: number = 10,
    offset: number = 0,
    search: string = "",
    sort: string = ""
  ): Promise<IPagedCollection<IMainProduct>> =>
    requests.get(
      `/MainProducts/GetMainProductsAdmin?limit=${limit}&${
        categoryId ? `categoryId=${categoryId}&` : ""
      }${offset === 0 ? "" : `offset=${offset}&`}${sort}${search}`
    ),
  mainProductsByCategoryId: (
    id: string,
    limit: number = 100,
    offset: number = 0
  ): Promise<IPagedCollection<IMainProduct>> =>
    requests.get(
      `/MainProducts?Offset=${offset}&limit=${limit}&categoryId=${id}`
    ),
  productsByMainProductId: (
    id: string,
    limit: number = 16,
    offset: number,
    search?: string,
    sort: string = ""
  ): Promise<IPagedCollection<ISoloProduct>> =>
    requests.get(
      `/MainProducts/ProductsByMainProductId/${id}?limit=${limit}${
        offset === 0 ? "" : `&offset=${offset}`
      }${sort}${search ? search : ""}`
    ),
  solo: (id: string): Promise<IMainWithProducts> =>
    requests.get(`/MainProducts/${id}`),
  create: (form: FormData): Promise<{}> => requests.post("/MainProducts", form),
  edit: (form: FormData, id: string): Promise<{}> =>
    requests.put(`/MainProducts/${id}`, form),
  delete: (id: string): Promise<{}> => requests.del(`/MainProducts/${id}`),
  mainWithProducts: (mainProductId: string): Promise<IMainWithProducts> =>
    requests.get(`/MainProducts/Solo/${mainProductId}`),
  copy: (id: string): Promise<number> =>
    requests.post("/MainProducts/CopyMainProduct", {
      id: id,
    }),
  changePicture: (form: FormData): Promise<{}> =>
    requests.post("/MainProducts/ChangePicture", form),
};
const Excels = {
  importExcel: (form: FormData): Promise<{}> =>
    requests.post("/Excels/ImportExcelFile", form),
};
const Setting = {
  get: (): Promise<IShopDetail> => requests.get("/Settings"),
  edit: (form: IShopDetail): Promise<IShopDetail> =>
    requests.put("/Settings", form),
  addAutomaticOrdering: (form: IAutoOrdering): Promise<IAutoOrdering> =>
    requests.post("/Settings/AddAutomaticOrdering", form),
  getAutomaticOrderingList: (): Promise<ICron[]> =>
    requests.get("/Settings/AutomaticOrderingList"),
  getOpenTimes: (): Promise<IOpenTimes[]> =>
    requests.get("/Settings/OpenTimes"),
  removeAutomaticOrdering: (id: string): Promise<{}> =>
    requests.del(`/Settings/RemoveAutomaticOrdering/${id}`),
  getPrintSettings: (): Promise<boolean> =>
    requests.get("/Settings/GetPrintSettings"),
  changePrintSettings: (form: IPrintSetting): Promise<IShopDetail> =>
    requests.put("/Settings/ChangePrintSettings", form),
  deliveryStartEndTime: (
    pickupInStore: boolean
  ): Promise<IDeliveryStartEndTime> =>
    requests.get(
      `/Settings/DeliveryStartEndTime?pickupInStore=${pickupInStore}`
    ),
};
const PostCode = {
  postCodeSelectList: (
    postCode: string,
    offset: number,
    limit: number
  ): Promise<IPagedCollection<SelectList<string>>> =>
    requests.get(
      `/PostCodes/PostCodeSelectList?Offset=${offset}&Limit=${limit}&postCode=${postCode}`
    ),
};
const Tables = {
  adminList: (
    limit: number = 10,
    offset: number = 0
  ): Promise<IPagedCollection<ITables>> =>
    requests.get(`/Tables?Offset=${offset}&Limit=${limit}`),
  getById: (id: string): Promise<IModifyTable> => requests.get(`/Tables/${id}`),
  modify: (form: IModifyTable, id?: string): Promise<{}> => {
    if (id) {
      return requests.put(`/Tables/${id}`, form);
    } else {
      return requests.post("/Tables", form);
    }
  },
  delete: (id: string): Promise<{}> => requests.del(`/Tables/${id}`),
};
const BookingTables = {
  adminList: (
    limit: number,
    offset: number = 0,
    sort: string = "",
    search: string = ""
  ): Promise<IPagedCollection<IBookingTables>> =>
    requests.get(
      `/BookingTables?Offset=${offset}&Limit=${limit}${sort}${search}`
    ),
  getById: (id: string): Promise<IGetBookingTable> =>
    requests.get(`/BookingTables/${id}`),
  modify: (form: IModifyBookingTable, id?: string): Promise<{}> => {
    if (id) {
      return requests.put(`/BookingTables/${id}`, form);
    } else {
      return requests.post("/BookingTables", form);
    }
  },
  delete: (id: string): Promise<{}> => requests.del(`/BookingTables/${id}`),
};
const Reports = {
  category: (from: string, to: string): Promise<ICategoryReport[]> =>
    requests.get(`Reports/BaseOnCategory?from=${from}&to=${to}`),
  productsSize: (from: string, to: string): Promise<IProductsSizeReport[]> =>
    requests.get(`Reports/BaseOnProduct?from=${from}&to=${to}`),
};
const Drivers = {
  selectListDrivers: (
    limit: number = 100,
    offset: number = 0
  ): Promise<IPagedCollection<SelectList<string>>> =>
    requests.get(
      `/Drivers/GetDriversSelectList?Offset=${offset}&Limit=${limit}`
    ),
  assignOrder: (form: IAssignOrder): Promise<{}> =>
    requests.post("/Drivers/AssignOrder", form),
};

const DeliveryCharges = {
  adminList: (
    limit: number,
    offset: number = 0,
    sort: string = "",
    search: string = ""
  ): Promise<IPagedCollection<IDeliveryCharge>> =>
    requests.get(
      `/DeliveryCharges/DeliveryChargeList?Offset=${offset}&Limit=${limit}${sort}${search}`
    ),
  getById: (id: string): Promise<IDeliveryCharge> =>
    requests.get(`/DeliveryCharges/${id}`),
  modify: (form: IModifyDeliveryCharge, id?: string): Promise<{}> => {
    if (id) {
      return requests.put(`/DeliveryCharges/${id}`, form);
    } else {
      return requests.post("/DeliveryCharges", form);
    }
  },
  delete: (id: string): Promise<{}> => requests.del(`/DeliveryCharges/${id}`),
  value: (postCode: string): Promise<IDeliveryChargeValue> =>
    requests.get(`DeliveryCharges/DeliveryChargeValue?postCode=${postCode}`),
};

const TierPrices = {
  adminList: (
    limit: number,
    offset: number = 0,
    sort: string = "",
    search: string = ""
  ): Promise<IPagedCollection<ITierPrice>> =>
    requests.get(`/TierPrices?Offset=${offset}&Limit=${limit}${sort}${search}`),

  getTierOfProduct: (productId: string): Promise<ITierPrice> =>
    requests.get(`/TierPrices/GetTierOfProduct?productId=${productId}`),

  getClientTierPrices: (): Promise<ITierPrice[]> =>
    requests.get("/TierPrices/ClientTierPrices"),

  modify: (form: IModifyTierPrice, id?: string): Promise<{}> =>
    id
      ? requests.put(`/TierPrices/PutTierProduct/${id}`, form)
      : requests.post("/TierPrices/PostTierProduct", form),

  delete: (id: string): Promise<{}> => requests.del(`/TierPrices/${id}`),
  removeProducts: (ids: string[]): Promise<{}> =>
    requests.post("/TierPrices/DeleteTierProducts", ids),
};

const Payments = {
  getPaymentSenseToken: (orderId: string): Promise<IPSAccessToken> =>
    requests.get(`/Payments/GetTokenPaymentSense?orderId=${orderId}`),
  RedirectEvoPayment: (orderId: string): Promise<string> =>
    requests.get(`/Payments/RedirectEvoPayment?orderId=${orderId}`),
};

const TextSlider = {
  get: (): Promise<ITextSlider[]> => requests.get("/TextSliders"),
  post: (form: ITextSlider): Promise<{}> => requests.post("/TextSliders", form),
  remove: (id: string): Promise<{}> => requests.del(`/TextSliders?id=${id}`),
};

const Freebies = {
  get: (): Promise<IFreebies[]> => requests.get(`/Freebies/`),
  getById: (id: string): Promise<IFreebies> => requests.get(`/Freebies/${id}`),
  modify: (form: ICreateFreebies, id?: string): Promise<{}> =>
    id ? requests.put("/Freebies", form) : requests.post("/Freebies", form),

  addProducts: (form: IFreebiesProducts): Promise<{}> =>
    requests.post("/Freebies/CreateProductFreebies", form),
  removeProducts: (form: IFreebiesProducts): Promise<{}> =>
    requests.post("/Freebies/DeleteProductFreebies", form),
};

const DeliveryTimes = {
  List: (
    limit: number = 10,
    offset: number = 0
  ): Promise<IPagedCollection<IDeliveryTime>> =>
    requests.get(`/DeliveryTimes?Offset=${offset}&Limit=${limit}`),
  getById: (id: string): Promise<any> => requests.get(`/DeliveryTimes/${id}`),
  modify: (form: IModifyDeliveryTime, id?: string): Promise<{}> => {
    if (id) {
      return requests.put(`/DeliveryTimes/${id}`, form);
    } else {
      return requests.post("/DeliveryTimes", form);
    }
  },
  delete: (id: string): Promise<{}> => requests.del(`/DeliveryTimes/${id}`),
  canDelivery: (): Promise<boolean> =>
    requests.get(`/DeliveryTimes/CanDelivery`),
};

const Cart = {
  get: (id: string | null): Promise<ICart> =>
    requests.get(`/Cart${id ? `?id=${id}` : ""}`),
  post: (form: ICartRequest): Promise<ICart> => requests.post("/Cart", form),
};
const Voucher = {
  hasActiveVoucher: (): Promise<boolean> =>
    requests.get(`/Vouchers/HasActiveVoucher`),
};

export default {
  MainProduct,
  Notification,
  Role,
  ImageWebSite,
  Pdf,
  Order,
  Address,
  Products,
  User,
  Categories,
  ProductAttribute,
  ProductAttributeMapping,
  ProductAttributeValue,
  Information,
  Excels,
  Setting,
  PostCode,
  Tables,
  BookingTables,
  Reports,
  Drivers,
  DeliveryCharges,
  TierPrices,
  Payments,
  TextSlider,
  Freebies,
  DeliveryTimes,
  Cart,
  Voucher,
};
