import React from "react";
import {
  createTheme,
  StylesProvider,
  ThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import { faIR } from "@material-ui/core/locale";
import { CssBaseline } from "@material-ui/core";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const theme = createTheme(
  {
    direction: "rtl",
    customPalette: {
      menuText: "#000",
      menuBg: "#fff",
      fontHeaderColor: "#fff",
    },
  },
  faIR
);

const RTLTheme: React.FC = (props) => {
  return (
    <StylesProvider jss={jss}>
      <CssBaseline />
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </StylesProvider>
  );
};
export default RTLTheme;
