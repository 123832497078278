import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Paper,
  Button,
  Link,
} from "@material-ui/core";

import { combineValidators, isRequired } from "revalidate";
import { FORM_ERROR } from "final-form";

import { ILogin } from "../../Models/user";
import agent from "../../Services/agent";
import ErrorMessage from "../../utils/ErrorMessage";
import TextInput from "../../utils/TextInput";
import { setToken } from "../../Stores/userSlice";
import PasswordForgot from "./ResetPassword/PasswordForgot";
import { toast } from "react-toastify";
import CheckboxUtil from "../../utils/MaterialUi/CheckboxUtil";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1,
    },
    button: {
      margin: "1rem",
    },
    paper: {
      padding: "1rem",
      margin: "1rem",
    },
  })
);

interface IProps {
  handleSubmit: () => void;
}

const SignIn: React.FC<IProps> = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const [passResetModal, setPassResetModal] = useState(false);
  const [email, setEmail] = useState("");
  const validate = combineValidators({
    userName: isRequired({ message: t("required") }),
    password: isRequired({ message: t("required") }),
  });
  const loginForm: ILogin = {
    password: "",
    userName: "",
    rememberMe: false,
  };
  const dispatch = useDispatch();
  const classes = useStyles();
  const resetPassword = async (email: string) => {
    agent.User.resetPasswordRequest(email)
      .then((res) => {
        toast.success(t("checkInboxOrSpamOfYourEmail"));
      })
      .catch((error) => {
        if (error.status === 404) {
          toast.error(t("youAreNotRegisterYet"));
        } else {
          toast.error(t("somethingWentWrongTryAgain"));
        }
      });
  };
  return (
    <Grid item xs={12} sm={6} lg={4} className={classes.root}>
      <PasswordForgot
        email={email}
        setEmail={setEmail}
        resetPassword={resetPassword}
        passResetModal={passResetModal}
        setPassResetModal={setPassResetModal}
      />
      <Form
        validate={validate}
        initialValues={loginForm}
        onSubmit={(values: ILogin) =>
          agent.User.login(values)
            .then((res) => {
              dispatch(setToken(res));
              handleSubmit();
            })
            .catch((error) => {
              return {
                [FORM_ERROR]: error,
              };
            })
        }
        render={({
          handleSubmit,
          invalid,
          pristine,
          submitting,
          form,
          submitError,
          dirtySinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Paper className={classes.paper}>
              <h3>{t("login")}</h3>
              {/* {submitError && submitError.data.errors && !dirtySinceLastSubmit && ( */}
              {submitError && !dirtySinceLastSubmit && (
                <ErrorMessage text="Error(s)" error={submitError} />
              )}
              <Field
                label={t("userName")}
                autoFocus
                name="userName"
                component={TextInput}
                placeholder={t("userName")}
              />
              <Field
                label={t("password")}
                name="password"
                component={TextInput}
                type="password"
                placeholder={t("password")}
              />
              <Field
                label={t("rememberMe")}
                name="rememberMe"
                component={CheckboxUtil}
                type="checkbox"
                placeholder={t("rememberMe")}
              />
              <p>
                {t("forgotYourPasswordClickTo")}&nbsp;
                <Link
                  color="primary"
                  style={{ fontWeight: 600, cursor: "pointer" }}
                  onClick={() => setPassResetModal(true)}
                >
                  {t("resetYourPassword")}
                </Link>
              </p>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={(invalid && !dirtySinceLastSubmit) || pristine}
              >
                {t("login")}
              </Button>
              {/* <pre dir="ltr">{JSON.stringify(form.getState(), null, 2)}</pre> */}
            </Paper>
          </form>
        )}
      />
    </Grid>
  );
};

export default SignIn;
