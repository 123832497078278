import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const CategoryMenu2Styles = makeStyles((theme: Theme) =>
  createStyles({
    categoryContainer: {
      display: "flex",
      flexDirection: "column",
      padding: "0 !important",
      // backgroundColor: "#00000050",
      border: "1px solid #fff",
      borderRadius: 5,
      "@media (max-width: 767px)": {
        padding: "1rem 1.5rem",
      },
      "&.categoryContainerOnKiosk": {
        padding: "10px 0 !important",
        backgroundColor: theme.palette.primary.main,
        border: "none !important",
      },
      "& h4": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "5px 5px 0 0",
        textAlign: "center",
        fontWeight: "bold",
        marginBottom: 0,
        padding: "5px 0",
        borderBottom: `2px solid  ${theme.palette.text.primary}`,
        "@media only screen and (min-width: 768px)": {
          fontSize: "1.3rem",
        },
      },
    },
    discountsProducts: {
      borderBottom: "1px solid gray",
      padding: 4,
      cursor: "pointer",
      backgroundColor: "#1A2E4F",
      borderRadius: "0 0 5px 5px",

      "& .name": {
        color: "#F7D800",
        fontSize: "1.5rem",
        fontWeight: 600,
        textAlign: "center",
        borderRadius: 2,
        margin: "auto 0",
        transitionDuration: "0.2s",

        "&&:hover": {
          transform: "scale(1.1)",
        },

        "@media only screen and (min-width: 768px)": {
          fontSize: "1.1rem",
        },
      },
      "&.active": {
        backgroundColor: "#F7D800",
        "& .name": {
          color: "#1A2E4F",
        },
      },
    },
  })
);
