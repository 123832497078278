import { PayloadAction } from "@reduxjs/toolkit";
import createGenericSlice from "./GenericSlice";
import { GenericState } from "../Models/state";
import { ICart } from "../Models/Cart/ICart";
import { Slide, toast } from "react-toastify";

interface ILocalStorageCart {
  expirationDate: Date;
  id: string;
}

export const slice = createGenericSlice({
  name: "redisCart",
  initialState: {
    status: "loading",
    data: {
      isSuccess: false,
      responseText: "",
      userCart: {
        carts: [],
        delivery: false,
        deliveryCharge: 0,
        discount: 0,
        freebieId: "",
        freebieShows: [],
        id: getCartId("cartId"),
        postCode: "",
        showFreebie: false,
        subTotal: 0,
        total: 0,
      },
    },
  } as GenericState<ICart>,
  reducers: {
    setCart: (state, action: PayloadAction<ICart>) => {
      state.data = { ...action.payload };
      if (state.data) {
        setCartId("cartId", state.data.userCart.id, 24 * 60);
      }
    },
    addToCart: (state, action: PayloadAction<ICart>) => {
      state.data = { ...action.payload };
      if (state.data) {
        if (action.payload.responseText)
          toast.success(action.payload.responseText, {
            autoClose: 1000,
            transition: Slide,
          });
      }
    },
    emptyCart: (state) => {
      state.data = {
        isSuccess: false,
        responseText: "",
        userCart: {
          carts: [],
          delivery: false,
          deliveryCharge: 0,
          discount: 0,
          freebieId: "",
          freebieShows: [],
          id: "",
          postCode: "",
          showFreebie: false,
          subTotal: 0,
          total: 0,
        },
      };
      window.localStorage.removeItem("cartId");
    },
  },
});

function setCartId(key: string, value: string, expirationInMin = 10) {
  window.localStorage.removeItem(key);
  let expirationDate = new Date(new Date().getTime() + 60000 * expirationInMin);
  let newValue: ILocalStorageCart = {
    id: value,
    expirationDate: expirationDate,
  };
  window.localStorage.setItem(key, JSON.stringify(newValue));
}

function getCartId(key: string) {
  let stringValue = window.localStorage.getItem(key);
  if (stringValue) {
    let value: ILocalStorageCart = JSON.parse(stringValue);
    let expirationDate = new Date(value.expirationDate);
    if (expirationDate > new Date()) {
      return value.id;
    } else {
      window.localStorage.removeItem(key);
    }
  }
  return null;
}

export const selectCart = (state: { redisCart: { data: ICart } }) =>
  state.redisCart.data;

export const { setCart, emptyCart, addToCart } = slice.actions;
export default slice.reducer;
