import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { createTheme, ThemeProvider } from "@material-ui/core";

import Navbar2 from "../components/Design2/Navbar2/Navbar2";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer2 from "../components/Design2/Footer2/Footer2";
import { selectInformation } from "../Stores/informationSlice";
import Loading from "../components/Loading/Loading";
import Sign from "../Pages/Sign";
import InformationPage from "../components/InformationPage/InformationPage";
import ContactUs from "../components/ContactUs/ContactUs";
import ParkingInfo from "../components/ParkingInfo/ParkingInfo";
import LoggedInRoute from "../components/Common/LoggedInRoute/LoggedInRoute";
import { selectUser } from "../Stores/userSlice";
import Profile from "../Pages/Profile";
import Cart2 from "../components/Design2/Cart2/Cart2";
import CheckoutPage from "../Pages/CheckoutPage";
import PaymentPage from "../Pages/PaymentPage";
import Ordering3 from "../Pages/Ordering3";
import GuestRegister from "../components/Sign/GuestRegister/GuestRegister";
import ResetPassword from "../components/Sign/ResetPassword/ResetPassword";
import SubmitBooking from "../components/BookingTable/SubmitBooking/SubmitBooking";
import EditBooking from "../components/BookingTable/EditBooking/EditBooking";
import CheckBooking from "../components/BookingTable/CheckBooking";
import OpeningTimes from "../components/OpeningTimes/OpeningTimes";
import LandingPage from "../components/Skin3/LandingPage/LandingPage";
import _404Page from "../components/404Page/_404Page";
import Offers from "../components/Offers/Offers";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    bg: Palette["primary"];
    header: Palette["primary"];
    title: Palette["primary"];
  }
  interface PaletteOptions {
    bg?: PaletteOptions["primary"];
    header?: PaletteOptions["primary"];
    title?: PaletteOptions["primary"];
  }
}

const Skin3 = () => {
  const information = useSelector(selectInformation);
  const user = useSelector(selectUser);

  const theme = createTheme({
    direction: information.lang === "Persian" ? "rtl" : "ltr",
    typography: {
      fontFamily: information.lang === "Persian" ? "Samim" : "Arial",
    },
    palette: {
      type: information.darkTheme ? "dark" : "light",
      // Main
      primary: {
        main: information.mainColor ? information.mainColor : "#284919",
      },
      // Accent
      secondary: {
        main: information.accentColor ? information.accentColor : "#FC7D36",
      },
      // Background
      bg: {
        main: information.backGroundColor
          ? information.backGroundColor
          : "#E1CA9E",
      },
      // Header
      header: {
        main: information.headerColor ? information.headerColor : "#c00",
      },
      // Text
      title: {
        main: information.textColor ? information.textColor : "#fff",
      },
    },
    customPalette: {
      menuText: information.textMenuColor ?? "#000",
      menuBg: information.backGroundMenuColor ?? "#fff",
      fontHeaderColor: information.fontHeaderColor ?? "#fff",
    },
  });

  if (!information) return <Loading />;

  return (
    <ThemeProvider theme={theme}>
      <div className="fullHeight">
        <Navbar2
          address={information.tell}
          name={information.name}
          telephone={information.phoneNumber}
          openOrder={information.openOrder}
          temporaryShopClose={information.temporaryShopClose}
        />
        <main
          style={{
            height: "100%",
            backgroundColor: information.backGroundColor,
          }}
        >
          <Switch>
            <LoggedInRoute
              isLoggedIn={!!user.token}
              isInRole={true}
              path="/profile"
              component={Profile}
            />
            <Route
              path="/booking/:time/:selectedDate/:person"
              component={SubmitBooking}
            />
            <Route path="/edit" component={EditBooking} />
            <Route path="/account/resetPassword" component={ResetPassword} />
            <Route path="/payment/:orderId" component={PaymentPage} />
            <Route path="/checkout" component={Cart2} />
            <Route path="/contact" component={ContactUs} />
            <Route path="/parking" component={ParkingInfo} />
            <Route
              path="/about"
              component={() => (
                <InformationPage htmlValue={information.aboutUs} />
              )}
            />
            <Route
              path="/privacy"
              component={() => (
                <InformationPage htmlValue={information.privacyPolicy} />
              )}
            />
            <Route path="/opening-time" component={OpeningTimes} />
            <Route path="/offers" component={Offers} />
            <Route
              path="/allergy-advice"
              component={() => (
                <InformationPage htmlValue={information.returns} />
              )}
            />
            <Route path="/address" component={CheckoutPage} />
            <Route path="/guest-ordering" component={GuestRegister} />
            <Route
              path="/conditions"
              component={() => (
                <InformationPage htmlValue={information.termsCondition} />
              )}
            />
            <Route
              path="/delivery-information"
              component={() => (
                <InformationPage htmlValue={information.deliveryInformation} />
              )}
            />
            <Route path="/login/:backUrl?" component={Sign} />
            <Route path="/ordering" component={Ordering3} />
            <Route path="/table-reserve" component={CheckBooking} />
            <Route path="/404" component={_404Page} />
            <Route path="/" exact component={LandingPage} />
            <Route>
              <Redirect to="/404" />
            </Route>
          </Switch>
        </main>
        <Footer2
          hasOnlinePayment={
            information.payPal ||
            information.worldPay ||
            information.paymentSense
          }
          hasPayPal={information.payPal}
          facebook={information.facebook}
          instagram={information.instagram}
          twitter={information.twitter}
          whatsapp={information.whatsapp}
          tiktok={information.tikTok}
          copyWrite={information.copyWrite}
        />
      </div>
    </ThemeProvider>
  );
};

export default Skin3;
